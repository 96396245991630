@import "../../global-styles/variables";

/// INDEX
.home__main {
    min-height: 540px;
    height: 100vh;

    .twenty-two {
        background-color: transparent;
    }
}
//footer
.footer-brand {
    .container {
        padding: 0;
        @media (min-width: $breakpoint-md) {
            padding: 0 30px;
        }
    }
    .footer-brand__content {
        p {
            font-size: 20px;
            line-height: 24px;

            @media (min-width: $breakpoint-md) {
                font-size: 24px;
                line-height: 28px;
            }
        }
        .footer-brand__content__link {
            width: 150px;
            @media (min-width: $breakpoint-md) {
                max-width: 243px;
                width: 243px;
            }
            @media (min-width: $breakpoint-lg) {
                max-width: 143px;
                width: 143px;
            }
            @media (min-width: $breakpoint-xxl) {
                max-width: 243px;
                width: 243px;
            }
        }
        .footer-brand__content__link__logo {
            width: 150px;

            @media (min-width: $breakpoint-md) {
                max-width: 243px;
                width: 243px;
            }
            @media (min-width: $breakpoint-lg) {
                max-width: 143px;
                width: 143px;
            }
            @media (min-width: $breakpoint-xxl) {
                max-width: 243px;
                width: 243px;
            }
        }  
    }
    
}