@import "../../global-styles/variables";

.ril__image {
    width: 60%;
    max-width: 500px;
}

.glr__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    align-items: center;
    justify-content: center;
    justify-items: center;

    img {
        cursor: pointer;
        width: 120px;
    }
}

.glr__list__item {
    position: relative;
    &::before {
        // Get 1:1 divs
        content: "";
        padding-bottom: 100%;
        display: inline-block;
        vertical-align: top;
    }
    &::after {
        content: "Play";
        position: absolute;
        top: 2px;
        right: 2px;
    }
}

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
    .glr__list {
        grid-template-columns: repeat(4, 1fr);
        padding: 0 20px;
        img {
            width: 100%;
        }
    }
}

// 1200px
@media (min-width: $breakpoint-xl) {
    .glr__list {
        grid-template-columns: repeat(6, 1fr);
    }
}
