@import "../../global-styles/variables";

$border: 5px;
/// INDEX
//  + General
//  + Description
//  + Speakers
//  + Line up
//  + Sponsors
//  + After conf Footer
//  + Footer
//  [ Responsive ]

/* BACKGROUND SETTINGS */
$circle-colors: #2c4981 #fcb83b #1a4cffaa #2cce93 #2c4981 #fcb83b #1a4cff97 #2cce93 #2c4981
                #2c4981 #2cce93 #1a4bffb3 #2cce93 #2c4981 #2cce93 #1a4bffa5 #2cce93 #1a4bffa5
                #2c4981 #1a4bffb3 #1a4bffb3 #2cce93 #2c4981 #2c4981 #1a4bffa5 #2cce93 #2c4981;
$dots-quantity: 20;
$animation-delay: -40s;
$animation-duration: 70s;

@mixin dots($count) {
  $text-shadow: ();
  @for $i from 1 through $count {
    $text-shadow: $text-shadow,
                  (-1.5+(random()) * 3) + em
                  (-1.5+(random()) * 3) + em
                  7px
                  nth($list: $circle-colors, $n: $i);
  }
  text-shadow: $text-shadow;
}

@keyframes move {
    from {
      transform: rotate(0deg) scale(40) translateX(-10px);
    }
    to {
      transform: rotate(360deg) scale(30) translateX(10px);
    }
}

//------------------------------
//  + General
.twenty-two {
    background-color: #2E1646;
    z-index: 1;
    display: block;
    font-size: 52px;
    color: transparent;
    overflow-y: visible;

    &:before, &:after {
        position: fixed;
        top: 20%;
        left: 20%;
        width: 1.6em;
        height: 1.6em;
        content: '.';
        mix-blend-mode: screen;
        animation: $animation-duration $animation-delay move infinite ease-in-out alternate;
        z-index: 1;
    }
    &:before {
        @include dots($dots-quantity);
        animation-duration: $animation-duration;
        animation-delay: $animation-delay;
    }
    &:after {
        @include dots($dots-quantity);
        animation-duration: $animation-duration;
        animation-delay: -32s;
        top: 50%;
        left: 50%;
    }
    
}

.circle__header {
    position: absolute;
    width: 200px;
    &--eight {
        display: none;
        width: 80px;
    }
    &--seventh {
        display: block;
        right: -240px;
        width: 500px;
        top: 100px;
    }
    @media (min-width: $breakpoint-md) {
        position: absolute;
        &--seventh {
            display: block;
            width: 500px;
            right: -220px;
            top: 170px;
        }
        &--eight {
            display: block;
            right: 100px;
            top: 315px;
        }
    }
    @media (min-width: $breakpoint-xl) {
        position: absolute;
        &--seventh {
            width: 800px;
            right: -340px;
            top: 170px;
        }
        &--eight {
            right: 227px;
            top: 510px;        
        }
    }
    @media (min-width: $container-width) {
        &--seventh {
            width: 900px;
            right: -340px;
            top: 300px;
        }
    }
    @media (min-width: 1600px) {
        &--seventh {
            width: 900px;
            right: -269px;
            top: 300px;
        }
    }
    @media (min-width: $breakpoint-xxl) {
        &--seventh {
            right: -210px;
            top: 330px;
        }
    }
}
.twenty-two__container {
    background: transparent;
    max-width: 1600px;
    margin: auto;
    position: relative;
    width: 100%;
    z-index: 2;

    .empty__space {
        background-color: transparent;
        height: 150px;
        width: 100%;
        &--first {
            position: relative;
        }
    
        @media (min-width: $breakpoint-md) {
            height: 150px;
    
            &--third {
                height: 160px;
            }
        }
    
        @media (min-width: $breakpoint-xl) {
            height: 550px;
            &--second {
                height: 190px;
            }
    
            &--third {
                height: 320px;
            }
        }
        @media (min-width: 1600px){
            height: 550px;
            &--second {
                height: 100px;
            }
    
            &--third {
                height: 350px;
            }
        }
        @media (min-width: $breakpoint-xxl) {
            height: 500px;
            &--second {
                height: 200px;
            }
    
            &--third {
                height: 260px;
            }
        }
    }
}
.twenty-two-looper__main {
    position: relative;
    width: 100%;
    height: 1px;
}
.twenty-two-looper-img {
    display: none;

    @media (min-width: $breakpoint-md) {
        display: block;
        position: absolute;
        top: 205px;
        width: 1080px;
        right: -675px;
    }
    @media (min-width: $breakpoint-xl) {
        top: 350px;
        width: 1200px;
        right: -680px;
    }
}
.twenty-two {
    .first__bg__dot {
        position: absolute;
        right: 0;
        top: 0;
    }
    .second__bg__dot {
        position: absolute;
        right: 0;
        top: 550px;
        left: auto;
        bottom: auto;
    
        @media (min-width: $breakpoint-md) {
            top: 350px;
        }
        @media (min-width: $breakpoint-xl) {
            top: 350px;
            right: 0;
        }

        @media (min-width: $breakpoint-xxl) {
            top: 650px;
        }
    }
}
.footer__bg__main {
    background-color: #2E1646;
    position: relative;
    width: 100%;
}
.last__bg__dot {
    position: absolute;
    left: 0;
    width: auto;
    top: -232px;

    @media (min-width: $breakpoint-md) {
        width: 600px;
        top: -437px;
    }
    @media (min-width: $breakpoint-xl) {
        width: 700px;
        top: -507px;
    }
}
.twenty-two-header__main {
    min-height: 600px;
}
.header__main__content {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.twenty-two-header__main__title {
    position: initial;
    width: 100%;
    //top: -60px;
    text-align: center;
    &--section {
        color: $white;
        font-weight: 900;
        font-size: 46px;
    }

    @media (min-width: $breakpoint-md) {
        position: initial;
        margin-top: 60px;
        text-align: center;
        &--section {
            font-size: 64px;
            line-height: 70px;
            margin-top: 20px;
        }
    }
    @media (min-width: $breakpoint-lg) {
        margin-left: 0;
        margin-top: 60px;
    }
    @media (min-width: $breakpoint-xl) {
        margin-top: 40px;
        text-align: center;
        &--section {
            color: $white;
            font-weight: 900;
            font-size: 85px;
            line-height: 90px;
        }
    }
    @media (min-width: $container-width) {
        //margin-top: 110px;
        margin-top: 60px;
        &--section {
            font-size: 147px;
            line-height: 176px;
        }
    }
    @media (min-width: $breakpoint-xxl) {
        &--section {
            font-size: 140px;
            line-height: 176px;
        }
    }
}

.twenty-two__logo__img__revolution {
  text-align: center;
  &--img {
      margin-bottom: 10px;
      min-width: 210px;
      width: 300px;
      margin-left: 10px;  

      @media (min-width: $breakpoint-md) {
        margin-bottom: 15px;
        width: 420px;
        margin-left: 15px;
      }
      @media (min-width: $breakpoint-lg) {
        margin-bottom: 10px;
        margin-left: 15px;
        width: 460px;
      }
      @media (min-width: $breakpoint-xl) {
        width: 490px;
      }
      @media (min-width: $breakpoint-xxl) {
        margin-bottom: 30px;
        width: 680px;
        margin-left: 23px;
      }
  }
  @media (min-width: $breakpoint-md) {
    text-align: left;
  }
}
.twenty-two__logo__img__fintech {
  text-align: center;
  &--img {
    min-width: 210px;
    width: 310px;

    @media (min-width: $breakpoint-md) {
      margin-bottom: 15px;
      width: 430px;
    }
    @media (min-width: $breakpoint-lg) {
      text-align: left;
      margin-bottom: 10px;
      width: 468px;
    }
    @media (min-width: $breakpoint-xl) {
        width: 500px;
    }
    @media (min-width: $breakpoint-xxl) {
      margin-bottom: 0;
      width: 690px;
    }
  }

  @media (min-width: $breakpoint-md) {
    text-align: left;
  }
}

.header__main__left-side {
    position: relative;
    width: 100%;
    z-index: 3;

    @media (min-width: $breakpoint-md) {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
        padding-top: 20px;
        padding-left: 20px;
    }
    @media (min-width: $breakpoint-xl) {
      padding-left: 40px;
      gap: 50px;
    }
    @media (min-width: $breakpoint-xxl) {
      gap: 60px;
    }
}

//  + Button call to action
.twenty-two-button__main {
    
    @media (min-width: $breakpoint-md) {
    }
    @media (min-width: $breakpoint-lg) {
    }
    @media (min-width: $breakpoint-xl) {
    }
    @media (min-width: $breakpoint-xxl) {
    }
}
.twenty-two-header__main__right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    text-align: center;
    align-self: center;
    
    @media (min-width: $breakpoint-lg) {
        position: relative;
        top: -100px;
        width: 440px;
    }
    @media (min-width: $breakpoint-xl) {
        top: -60px;
        align-self: center;
        flex: 1 0 50%;
    }
    @media (min-width: $breakpoint-xxl) {
        gap: 20px;
        top: -60px;
        text-align: center;
    }
}

.twenty-two-header__main__img {
    position: relative;
    top: 0;
    width: 200px;
    z-index: 3;

    @media (min-width: $breakpoint-md) {
        width: 335px;
    }
    @media (min-width: $breakpoint-lg) {
        width: 440px;
    }
    @media (min-width: $breakpoint-xl) {
        width: 400px;
    }
    @media (min-width: $container-width) {
        width: 430px;
    }
    @media (min-width: $breakpoint-xxl) {
        width: 500px;
    }
}

.twenty-two-header__main__countries {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    
    @media (min-width: $breakpoint-sm) {
        gap: 15px;
    }
    @media (min-width: $breakpoint-md) {
      justify-content: flex-start;
      width: 100%;
      gap: 7px;
      margin-bottom: 40px;
      margin-top: 40px;
    }
    @media (min-width: $breakpoint-xl) {
      width: 100%;
      gap: 40px;
      margin-top: 0;
      margin-left: 0;
      //margin-bottom: 20px;
      margin-bottom: 0;
    }
    @media (min-width: $breakpoint-xxl) {
      margin-left: 0;
    }
}

.twenty-two-header__main__countries__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.twenty-two-header__main__countries__label {
    color: $white;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.14em;

    @media (min-width: $breakpoint-md) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (min-width: $breakpoint-xl) {
      font-size: 20px;
      line-height: 24px;
    }
    @media (min-width: 1600px) {
      font-size: 29px;
      line-height: 35px;
    }
}

.twenty-two-header__main__countries__img {
    width: 20px;
    height: 15px;
    margin-left: 2px;
}

.twenty-two__main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.twenty-two__logo__main {
    margin-top: 65px;
    margin-bottom: 30px;
}

.twenty-two__logo__img {
    width: 216px;
    &--first {
        padding-top: 60px;
        img {
            display: none;
            margin-top: 10px;
            margin-left: 10px;
            //width: 150px;
            width: 130px;
        }
    }
    &--second {
        width: 250px;
    }
}

// CONFERENCE IMAGE
.conf__img {
    width: 261px;
    height: 156px;
}

.conf__img__main {
    margin-bottom: 21px;
}

//  + Countdown timer
.countdown__container {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 30px;
    margin-bottom: 20px;

    .countdown_timer__main {
        margin: 0;
    }
    .countdown__main {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        margin: auto;
        max-width: 1400px;
        gap: 0;
        @media (min-width: $breakpoint-xl) {
            gap: 20px;
        }
    }
    .countdown__number {
        font-weight: bold;
        font-size: 42px;
        line-height: 51px;
        text-align: center;
        color: $white;

        @media (min-width: $breakpoint-md) {
            font-size: 36px;
            line-height: 45px;
        }
        
        @media (min-width: $breakpoint-xl) {
            font-size: 50px;
            line-height: 60px;
        }
    } 
    .countdown__text {
        font-size: 11px;
        line-height: 8px;
        text-align: center;
        color: rgba(255, 255, 255, 0.4);
        
        @media (min-width: $breakpoint-xl) {
            font-size: 12px;
            line-height: 14px;
        }
        
    } 
    .colon {
        align-self: flex-start;
        font-size: 60px;
        line-height: 20px;
        text-align: center;
        color: $white;
        margin: 0 10px;
        
        @media (min-width: $breakpoint-xl) {
            font-size: 35px;
            line-height: 32px;
        }
        @media (min-width: $breakpoint-xl) {
            font-size: 100px;
            line-height: 10px;
        }
    }

    @media (min-width: $breakpoint-xl) {
        gap: 50px;
    }
    @media (min-width: $breakpoint-xxl) {
        gap: 60px;
    }
}

//  + Button Call to action
.twenty-two-button__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 60px;
  margin: auto;
  max-width: 471px;

  position: relative;
  padding: 30px;
  box-sizing: border-box;

  color: #ffffff;
  background: rgb(44, 25, 67);
  background-clip: padding-box;
  border: solid $border transparent;
  border-radius: 60px;
  transition: 0.5s all ease-in-out;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;
    background: linear-gradient(to bottom, #006CF9, #17D3CD);
  }

  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(44, 25, 67, 0.4);
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: linear-gradient(to bottom, #006CF9, #17D3CD);
      transition: 0.5s all ease-in-out;
    }
  }

  @media (min-width: $breakpoint-md) {
    width: 350px;
  }
  @media (min-width: $breakpoint-xl) {
    height: 98px;
    width: 471px;
    p {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.twenty-two-description__content {
    background: rgba(26, 27, 35, 0.5);
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(42px);
    border-radius: 50px;
    margin: 40px 20px 0 20px;

    @media (min-width: $breakpoint-md) {
        margin: 0 30px;
    }
    @media (min-width: $breakpoint-xl) {
        margin: 0 35px;
    }
}

// + Description
.description__text {
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.08em;
    color: $white;

    &--conf {
        padding: 40px 20px;
        margin: 0 auto 50px auto;
    }
    
    &--speakers-twentytwo {
        margin: 25px auto;
    }
    b {
        color: $white;
    }

    @media (min-width: $breakpoint-md) {
        &--conf {
            margin: 50px auto;
            padding: 60px 20px;
        }

        &--speakers-twentytwo {
            padding: 0 30px;
        }
    }
    @media (min-width: $breakpoint-xl) {
        font-size: 28px;
        line-height: 38px;
        text-align: center;
        letter-spacing: 0.08em;

        &--speakers-twentytwo {
            padding: 0;
            margin: 25px auto;
        }
        &--conf {
            margin: 70px auto 172px auto;
            padding: 78px 70px;
        }
    }
    @media (min-width: $container-width) {
        &--conf {
            margin: 150px auto;
        }
        &--speakers-twentytwo {
            margin-left: 130px;
            align-self: flex-start;
            margin-top: 45px;
        }
    }
    @media (min-width: $breakpoint-xxl) {
        font-size: 23px;
        align-self: flex-start;

        &--speakers-twentytwo {
            margin-left: 125px;
            margin-top: 40px;
        }
        &--conf {
            padding: 78px 140px;
        }
    }
}

//  + Speakers
.circle__speakers-twentytwo {
    display: none;
}

.speakers-twentytwo__content {
    padding-bottom: 40px;
    @media (min-width: $breakpoint-md) {
        padding-bottom: 50px;
    }
    @media (min-width: $breakpoint-xl) {
        padding-bottom: 70px;
    }
    @media (min-width: $breakpoint-xxl) {
        padding-bottom: 80px;
    }
}

.speakers-twentytwo__main__images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    margin: 0 10px;

    @media (min-width: $breakpoint-md) {
        margin: 0 70px;
    }
    @media (min-width: $breakpoint-xl) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        z-index: 1;
    }
}

.speakers-twentytwo__image {
    &--first {
        position: absolute;
        left: -30px;
        width: 180px;
        top: -70px;
    }
    &--second {
        width: 330px;
        margin-right: 20px;
    }
    @media (min-width: $breakpoint-sm) {
        &--first {
            left: -50px;
            top: -90px;
            width: 300px;
        }
    }
    @media (min-width: $breakpoint-md) {
        &--first {
            left: -50px;
            top: -90px;
            width: 400px;
        }

    }
    @media (min-width: $breakpoint-xl) {
        width: 100%;
        &--first {
            width: 500px;
            left: -110px;
            top: -70px;
        }
        &--second {
            width: 450px;
            margin-right: 80px;
        }
    }
    @media (min-width: $container-width) {
        &--first {
            left: -120px;
            top: -170px;
            width: 650px;
         }
    }

}


.speakers-twentytwo-text {
    font-family: 'Lato';
    font-style: italic;
    text-shadow: 2px 3px #000000;
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    color: #17D3CD;
    
    @media (min-width: $breakpoint-sm) {
        font-size: 70px;
        line-height: 80px;
    }
    @media (min-width: $breakpoint-md) {
        text-shadow: 0 1px 0px #000000, 1px 0 0px #000000, 1px 2px 1px #000000, 2px 1px 1px #000000, 2px 3px 2px #000000, 3px 2px 2px #000000, 3px 4px 2px #000000, 4px 3px 3px #000000, 4px 5px 3px #000000, 5px 4px 2px #000000, 5px 6px 2px #000000, 6px 5px 2px #000000, 6px 7px 1px #000000, 7px 6px 1px #000000, 7px 8px 0px #000000, 8px 7px 0px #000000;
        font-size: 80px;
        line-height: 90px;
    }
    @media (min-width: $breakpoint-xl) {
        font-size: 100px;
        line-height: 120px;
    }
    @media (min-width: $breakpoint-xxl) {
        font-size: 140px;
        line-height: 192px;
        margin-right: 25px;
    }
}

.speakers-line {
    border: 2px solid #17D3CD;
    height: 0;
    position: absolute;
    right: 0;
    width: 180px;
    margin-top: 10px;
    @media (min-width: $breakpoint-sm) {
        width: 280px;
    }
    @media (min-width: $breakpoint-md) {
        width: 300px;
    }
    @media (min-width: $breakpoint-lg) {
        width: 500px;
    }
    @media (min-width: $breakpoint-xl) {
        width: 600px;
    }
    @media (min-width: $container-width) {
        width: 610px;
    }
    @media (min-width: $breakpoint-xxl) {
        margin-right: 40px;
        width: 800px;
    }
}

.first_speaker__main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 100px 20px;
    gap: 10px;
    position: relative;
    z-index: 5;
}

.speakers-twentytwo__avatars__img {
    width: 150px;

    @media (min-width: $breakpoint-md) {
        width: 220px;
        height: 213px;
    }

    @media (min-width: $breakpoint-xl) {
        width: 280px;
        height: 271px;
    }
    @media (min-width: $breakpoint-xxl) {
        width: 328px;
        height: 317px;
    }
}

.speakers-twentytwo__avatars__item {
    text-align: center;
    z-index: 2;
}

.speakers-twentytwo__avatars__text {
    @media (min-width: $breakpoint-xxl) {
        margin-top: 25px;
    } 
}

.speakers-twentytwo__avatars__label {
    color: $white;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;    
    text-align: center;
}

.speakers-twentytwo__avatars__description {
    color: $white;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;    
    text-align: center;
    font-style: italic;
    margin-top: 5px;
}

.speakers-twentytwo__mobile {
    display: block;
    width: 95vw;
    margin: auto;

    .swiper-container {
        .swiper-slide {
            width: 140px !important;
        }
    }
    @media (min-width: $breakpoint-md) {
        display: none;
    }
}

.speakers-twentytwo__avatars__main {
    display: none;

    @media (min-width: $breakpoint-md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row dense;
        column-gap: 0;
        row-gap: 70px;
        text-align: center;
    }
    @media (min-width: $breakpoint-xl) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 130px;
        margin: auto 40px;
        grid-gap: 110px;
    }
    @media (min-width: $breakpoint-xxl) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 110px;
        margin: 0;

        &--second {
            grid-template-columns: repeat(2, 1fr);
            margin-top: 110px;
            column-gap: 0;
            row-gap: 110px;

            .speakers-twentytwo__avatars__item {
                &:nth-child(odd) {
                    margin-left: 100px;
                }
                &:nth-child(even) {
                    margin-right: 100px;
                }
            }
        }
    }
}


//  + Memories
.memories__image {
    &--first {
        width: 150px;
        padding-top: 80px;
    }
    &--second {
        position: absolute;
        right: -20px;
        width: 200px;
    }
}

.memories__main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row dense;
    grid-gap: 40px;
    text-align: center;
    margin: 100px 40px 40px 40px;

    iframe {
        width: 200px;
        height: 200px;
    }
}

//  + Line up
.twenty-two-lineup__container__main {
    position: relative;
}

.twenty-two-lineup__main__content {
    margin: 0 20px;

    @media (min-width: $breakpoint-md) {
        margin: 0 70px;
    }
    @media (min-width: $breakpoint-xl) {
        margin: 0 50px;
    }
}

.twenty-two-lineup__main__images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.twenty-two-lineup__image {
    &--first {
        width: 150px;
        padding-top: 80px;
    }
    &--second {
        position: absolute;
        right: 0px;
        top: 90px;
        width: 200px;
        z-index: 3;
    }
    @media (min-width: $breakpoint-xsm) {
        &--second {
            right: 70px;
            top: 40px;
        }
    }
    @media (min-width: $breakpoint-md) {
        width: 150px;
        &--first {
            position: relative;
            width: 300px;
        }
        &--second {
            right: 0;
            top: -45px;
            width: 400px;
        }
    }
    @media (min-width: $breakpoint-lg) {
        &--second {
            right: 100px;
            top: -80px;
            width: 450px;
        }
    }
    @media (min-width: $breakpoint-xl) {
        &--second {
            right: 100px;
            top: -60px;
            width: 500px;
        }
    }
    @media (min-width: $container-width) {
        &--first {
            margin-left: 0;
            width: 550px;
        }
        &--second {
            right: 70px;
            width: 700px;
        }
    }
    @media (min-width: $breakpoint-xxl) {
        &--second {
            right: 155px;
            top: -60px;
            width: 700px;
        }
    }
}

.twenty-two-lineup__main {
    text-align: center;
    margin-top: 50px;
    @media (min-width: $container-width) {
        margin: 50px 60px 0 50px;
    }
    @media (min-width: $breakpoint-xxl) {
        margin: 50px 60px 0 40px;
    }
}

.line-up__title__main {
    margin: 50px 0 100px 0;
    text-align: center;
    z-index: 3;
    p {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 300;
        font-size: 50px;
        line-height: 60px;
        color: #FCB83C;
        text-shadow: 2px 3px #000000;

        @media (min-width: $breakpoint-sm) {
            font-size: 70px;
            line-height: 80px;
        }
        @media (min-width: $breakpoint-md) {
            font-size: 80px;
            text-shadow: 0 1px 0px #000000, 1px 0 0px #000000, 1px 2px 1px #000000, 2px 1px 1px #000000, 2px 3px 2px #000000, 3px 2px 2px #000000, 3px 4px 2px #000000, 4px 3px 3px #000000, 4px 5px 3px #000000, 5px 4px 2px #000000, 5px 6px 2px #000000, 6px 5px 2px #000000, 6px 7px 1px #000000, 7px 6px 1px #000000, 7px 8px 0px #000000, 8px 7px 0px #000000;
            line-height: 90px;
        }
        @media (min-width: $breakpoint-xl) {
            font-size: 100px;
            line-height: 120px;
        }
        @media (min-width: $breakpoint-xxl) {
            text-align: start;
            padding-left: 50px;
            font-size: 140px;
            line-height: 192px;
        }
    }
    .line-up__divider__line {
        border: 2px solid #FCB83C;
        height: 0;
        width: 240px;
        margin: 24px auto 0 auto;
        @media (min-width: $breakpoint-sm) {
            width: 280px;
        }
        @media (min-width: $breakpoint-md) {
            width: 380px;
        }
        @media (min-width: $breakpoint-lg) {
            width: 415px;
        }
        @media (min-width: $breakpoint-xl) {
            width: 700px;
        }
        @media (min-width: $container-width) {
            width: 740px;
        }
        @media (min-width: $breakpoint-xxl) {
            width: 840px;
        }
    }
    @media (min-width: $breakpoint-xl) {
        margin: 100px 0;
    }

    @media (min-width: $breakpoint-xxl) {
        margin: 150px 0 100px 0;
    }
}

.twenty-two-lineup__content {
    max-width: 1600px;
}

.twenty-two-lineup__description__main {
    border: 2px solid #17D3CD;
    margin-top: 35px;
    padding: 34px 21px;
    position: relative;
}


.twenty-two-lineup__description__text {
    font-size: 21px;
    line-height: 36px;
    letter-spacing: 0.14em;
    color: $white;
}

.twenty-two-lineup__timeline__main {
    margin-left: 0px;
    position: relative;

    @media (min-width: $breakpoint-xl) {
        margin-left: 30px;
    }
    @media (min-width: 1600px) {
        margin-left: 90px;
    }
    @media (min-width: $breakpoint-xxl) {
        margin-left: 45px;
    }
}

.twenty-two-lineup__timeline__plus_img {
    width: 100px;
    height: 100px;
    position: absolute;
    right: 0;
    &--first {
        right: 0px;
        top: -20px;
    }
    &--second {
        right: 20px;
        top: 60px;
        width: 150px;
        height: 150px;
    }
    &--third {
        right: -10px;
        top: 220px;
        width: 120px;
        height: 120px;
    }

    @media (min-width: $breakpoint-md) {
        display: block;
        &--first {
            right: 0px;
            top: -20px;
        }
        &--second {
            right: 120px;
            top: 60px;
            width: 150px;
            height: 150px;
        }
        &--third {
            right: 10px;
            top: 310px;
            width: 120px;
            height: 120px;
        }
    }
    @media (min-width: $breakpoint-xl) {
        &--first {
            right: 100px;
            top: -20px;
            width: 150px;
            height: 150px;
        }
        &--second {
            right: 260px;
            top: 280px;
            width: 250px;
            height: 250px;
        }
        &--third {
            right: 120px;
            top: 600px;
            width: 200px;
            height: 200px;
        }
    }
    @media (min-width: $breakpoint-xxl) {
        &--first {
            right: 200px;
            top: 45px;
            width: 150px;
            height: 150px;
        }
        &--second {
            right: 600px;
            top: 450px;
            width: 250px;
            height: 250px;
        }
        &--third {
            right: 220px;
            top: 800px;
            width: 200px;
            height: 200px;
        }
    }
}

.twenty-two-lineup__timeline__item {
    text-align: start;
    margin-left: 17px;
    margin-top: 20px;
    position: relative;

    &::before {
        content: '';
        background-color: $white;
        clip-path: circle(50% at 50% 50%);
        width: 10px;
        height: 10px;
        position: absolute;
        left: -19px;
        top: 6px;
    }

    &:first-child {
        margin-top: 0;
        padding-top: 20px;

        &::before {
            top: 26px;
        }
    }

    &:last-child {
        margin-top: 0;
        padding-top: 20px;

        &::before {
            top: 26px;
        }
    }

    @media (min-width: $breakpoint-sm) {
        margin-left: 15px;
    }
    @media (min-width: $breakpoint-md) {
        margin-left: 15px;
        margin-top: 32px;
    
        &::before {
            width: 15px;
            height: 15px;
            left: -32px;
            top: 6px;
        }
    
        &:first-child {
            margin-top: 0;
            padding-top: 32px;
    
            &::before {
                top: 37px;
            }
        }
    
        &:last-child {
            margin-top: 0;
            padding-top: 32px;
    
            &::before {
                top: 37px;
            }
        }
    }
    @media (min-width: $breakpoint-lg) {
        margin-left: 24px;
    }
    @media (min-width: $breakpoint-xl) {
        margin-left: 36px;
        margin-top: 52px;
    
        &::before {
            width: 24px;
            height: 24px;
            left: -49px;
            top: 16px;
        }
    
        &:first-child {
            margin-top: 0;
            padding-top: 62px;
    
            &::before {
                top: 77px;
            }
        }
    
        &:last-child {
            margin-top: 0;
            padding-top: 62px;
    
            &::before {
                top: 77px;
            }
        }
    }
}

.twenty-two-lineup__item__title {
    color: $white;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;    
    letter-spacing: 0.05em;
}

.twenty-two-lineup__item__help_text {
    color: $white;
    font-weight: 300;
    font-style: italic;
    font-size: 14px;
    line-height: 18px;    
    letter-spacing: 0.14em;

    &--secondary {
        font-weight: 500;
    }
    &--description {
        font-style: normal;
    }

    @media (min-width: $breakpoint-xl) {
        font-size: 20px;
        line-height: 50px;    
        letter-spacing: 0.14em;

        &--secondary {
            font-weight: 500;
        }
        &--description {
            line-height: 30px;
        }
    }
}

//  + Sponsors
.sponsors__main {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row dense;
    grid-gap: 20px;
    text-align: center;
    margin: 24px auto;
    width: 90%;

    position: relative;
    padding: 30px 0;
    box-sizing: border-box;

    background: rgb(44, 25, 67);
    background-clip: padding-box;
    border: solid 3px transparent;
    border-radius: 60px;
    transition: 0.5s all ease-in-out;

    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: -1;
        margin: -3px;
        border-radius: inherit;
        background: linear-gradient(to bottom, #FCB83C, #602692);
    }

    a {
        margin: auto;
        display: flex;
        align-items: center;
        align-content: center;
    }
    &--gold {
        a {
            width: 250px;
            height: 100px;
        }
    }
    &--silver {
        &:before {
            background: linear-gradient(to bottom, #17D3CD, #602692);
        }
        a {
            width: 220px;
        }
    }
    &--bronze {
        &:before {
            background: linear-gradient(to bottom, #006CF9, #602692);
        }
        a {
            width: 200px;
            height: 60px;
        }
    }

    @media (min-width: $breakpoint-md) {
        grid-template-columns: repeat(1, auto);
        &--gold {
            a {
                width: 310px;
            }
        }
        &--silver {
            grid-template-columns: repeat(1, auto);
            align-content: center;
            align-items: center;
        }
        &--bronze {
            grid-template-columns: repeat(1, auto);
        }
    }
    @media (min-width: $breakpoint-lg) {
        grid-template-columns: repeat(2, auto);
        &--silver {
            grid-template-columns: repeat(2, auto);
        }
        &--bronze {
            grid-template-columns: repeat(1, auto);
        }
    }
    @media (min-width: $breakpoint-xl) {
        grid-template-columns: repeat(3, auto);
        padding: 60px 30px;
        grid-gap: 10px;
        margin: 24px auto 0 auto;
        width: 95%;

        &--silver {
            grid-template-columns: repeat(2, auto);
        }
        &--bronze {
            grid-template-columns: repeat(1, auto);
        }
    }
}

.sponsors__title__main {
    margin: 100px 0;
    text-align: center;
    p {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 300;
        font-size: 50px;
        text-shadow: 2px 3px #000000;
        line-height: 60px;
        color: $blue;
        
        @media (min-width: $breakpoint-sm) {
            font-size: 70px;
            line-height: 80px;
        }
        @media (min-width: $breakpoint-md) {
            font-size: 80px;
            text-shadow: 0 1px 0px #000000, 1px 0 0px #000000, 1px 2px 1px #000000, 2px 1px 1px #000000, 2px 3px 2px #000000, 3px 2px 2px #000000, 3px 4px 2px #000000, 4px 3px 3px #000000, 4px 5px 3px #000000, 5px 4px 2px #000000, 5px 6px 2px #000000, 6px 5px 2px #000000, 6px 7px 1px #000000, 7px 6px 1px #000000, 7px 8px 0px #000000, 8px 7px 0px #000000;
            line-height: 90px;
        }
        @media (min-width: $breakpoint-xl) {
            font-size: 100px;
            line-height: 120px;
        }
        @media (min-width: $breakpoint-xxl) {
            font-size: 140px;
            line-height: 192px;
            margin-right: 25px;
        }
    }
    .sponsors__divider__line {
        border: 2px solid $blue;
        height: 0;
        width: 280px;
        margin: 24px auto 0 auto;
        @media (min-width: $breakpoint-sm) {
            width: 280px;
        }
        @media (min-width: $breakpoint-md) {
            width: 300px;
        }
        @media (min-width: $breakpoint-lg) {
            width: 500px;
        }
        @media (min-width: $breakpoint-xl) {
            width: 600px;
        }
        @media (min-width: $container-width) {
            width: 960px;
        }
    }
}

.twenty-two-sponsors__content {
    max-width: 1600px;
    margin: 110px 0;

    &:last-child {
        margin: 110px 0 0 0;
        padding-bottom: 0;
    }
}

.sponsors__title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    text-shadow: 2px 2px #000000;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.3em;

    &--gold {
        color: #FCB83C;
    }
    &--silver {
        color: #17D3CD;
    }
    &--bronze {
        color: $blue;
    }
}

.twenty-two-sponsors__img {
    margin: auto;
    width: 200px;
}
.sponsors__container__main {
    
    @media (min-width: $breakpoint-xl) {
        position: relative;
        z-index: 1;
    }
}

.sponsor__contact__main {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sponsor__contact__text {
    margin-top: 40px;
    margin-bottom: 50px;
    a {
        color: $white;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.1em;

        &:hover {
            text-decoration: underline;
            color: #F5B73C;
        }
    }

    @media (min-width: $breakpoint-md) {
        margin-top: 80px;
        margin-bottom: 0;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 1640px;
    padding: 0 30px;
}

//  + Footer
.footer-brand {
    &--from-home {
        background-color: transparent;
        box-shadow: inherit;
        bottom: 0;
        position: absolute;
        width: 100%;
        text-transform: capitalize;
        p {
            color: $white;
        }
        .container {
            justify-content: center;
        }
    }
}

//  + Hubspot form
.hubspot__form {
    max-width: 1000px;
    margin: auto;
    margin-top: 40px;
    position: relative;
    z-index: 2;

    @media (min-width: $breakpoint-md) {
        margin-top: 140px;
    }
}

.hubspot__form__title {
    color: $white;
    font-weight: 900;
    font-size: 36px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.hubspot__form__subtitle {
    text-align: center;
    margin: 20px 20px 50px 20px;
    @media (min-width: $breakpoint-md) {
        margin-top: 20px 0 50px 0;
    }
}

//  + Hubspot form
.sign_up__form {
    margin: 0 20px;
}

.circle__footer {
    display: none;
}

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
    .background__mobile {
        display: none;
    }
    .twenty-two__background {
        display: block;
        height: 100vh;
        position: absolute;
        width: 100%;
    }
    .twenty-two__logo__img {
        &--first {
            position: absolute;
            margin-top: 20px;
            margin-left: 30px;
            //width: 200px;
            width: 150px;
        }
        &--second {
            width: 70%;
            
            &--after-conf {
                width: 350px;
            }
        }
    }

    .header__main__content {
        padding-top: 130px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    
    .twenty-two-header__main__countries__img {
        width: 34px;
        height: 24px;
        margin-left: 1px;
    }

    .twenty-two__logo__main {
        margin-top: 0;
    }

    .twenty-two-button__label {
        font-size: 20px;
        line-height: 50px;
    }

    .first_speaker__main {
        padding: 125px 20px;
    }
    
    //  + Memories
    .memories__image {
        width: 150px;
        &--first {
            position: relative;
            width: 300px;
            margin-left: 40px;
        }
        &--second {
            right: -70px;
            width: 350px;
        }
    }

    .memories__mobile {
        display: none;
    }

    .after-conf-footer__main {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    //  + After conf Footer
    .after-conf-footer__main {
        position: relative;
        width: 100%;
        top: 90px;
    }
    
    .after-conf-footer__image {
        &--first {
            width: 350px;
            position: absolute;
            left: -65px;
        }

        &--second {
            width: 350px;
            position: absolute;
            right: 0;
        }
    }

    .memories__main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 40px;
        text-align: center;
        margin: 180px 40px 40px 40px;
    }

    .memories__main__video {
        iframe {
            width: 400px;
            height: 200px;
        }
    }


    //  + Sponsors

    //  + Hubspot form
    .sign_up__form {
        margin: 0 70px;
    }

    //  + Footer
    .footer-brand {
        padding: 20px 0;
        font-size: 14px;
    }

    .circle__footer {
        display: block;
        width: 450px;
        position: absolute;
        bottom: 0;
        right: 100px;
    }
}
// 1200px
@media (min-width: $breakpoint-xl) {
    .twenty-two__logo__img {
        &--first {
            position: absolute;
            margin-left: 30px;
            margin-top: 13px;
            width: 250px;
            display: none;
        }
        &--second {
            width: 480px;
        }
    }

    .header__main__content {
        padding-top: 100px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    
    .twenty-two-header__main__countries__img {
        width: 34px;
        height: 24px;
    }

    .twenty-two__logo__main {
        margin-top: 0;
    }

    //  + Conference image
    .conf__img {
        width: 100%;
        height: 100%;
    }

    //  + Button call to action

    //  + Speakers
    .circle__speakers-twentytwo {
        &--first {
            display: block;
            position: absolute;
            width: 600px;
            left: 0;
            bottom: -350px;
        }
        &--second {
            display: block;
            position: absolute;
            width: 400px;
            left: 330px;
            bottom: 100px;
        }
    }
    .first_speaker__main {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 200px 110px 100px 110px;
        gap: 50px;
        position: relative;
        z-index: 5;

        .speakers-twentytwo__avatars__img {
            margin-left: 10px;
            width: 280px;
        }
    }
    
    .speakers-twentytwo__avatars__label {
        font-weight: 800;
        font-size: 18px;
        line-height: 35px;
    }

    .speakers-twentytwo__avatars__description {
        font-weight: 500;
        font-size: 15px;
        line-height: 30px; 
        margin-top: 0;   
    }

    //  + Memories
    .memories__image {
        &--first {
            margin-left: 40px;
            width: 450px;
        }
        &--second {
            right: -70px;
            width: 600px;
        }
    }

    .memories__main {
        grid-template-columns: repeat(1, 1fr);
        margin: 300px 40px 50px 40px;
    }

    .memories__main__video {
        iframe {
            width: 600px;
            height: 400px;
        }
    }

    //  + After conf Footer
    .after-conf-footer__image {
        &--first {
            width: 400px;
        }

        &--second {
            width: 400px;
        }
    }

    //  + Line up 
    .twenty-two-lineup__container__main {
        position: relative;
        z-index: 1;
    }
    .twenty-two-lineup__description__main {
        margin-top: 66px;
        position: relative;
    }
    .twenty-two-lineup__description__text {
        font-weight: normal;
        font-size: 27px;
        line-height: 40px;
        letter-spacing: 0.14em;
        color: $white;
    }
    .twenty-two-lineup__item__title {
        color: $white;
        font-weight: 800;
        font-size: 30px;
        line-height: 55px;    
        letter-spacing: 0.05em;
    }

    //  + Sponsors

    //  + Footer
    .container {
        justify-content: center;
    }

    .hubspot__form__title {
        margin-top: 0;
        font-size: 50px;
    }

    .circle__footer {
        display: block;
        width: 450px;
        position: absolute;
        bottom: 0;
        right: 100px;
    }
}

//1400px
@media (min-width: $container-width) {

    .header__main__content {
        padding-top: 100px;
    }
    .twenty-two__logo__img {
        &--second {
            width: 600px;
        }
    }
    .twenty-two-header__main__countries__img {
        width: 34px;
        height: 24px;
    }

    //  + Speakers
    .speakers-twentytwo__avatars__label {
        font-weight: 800;
        font-size: 32px;
        line-height: 49px;
    }
    
    .speakers-twentytwo__avatars__description {
        font-weight: 500;
        font-size: 27px;
        line-height: 49px;
        margin-top: 0;   
    }

    //  + Memories
    .memories__image {
        &--first {
            margin-left: 60px;
            width: 450px;
        }
        &--second {
            right: -110px;
            width: 700px;
        }
    }

    .memories__main {
        margin: 300px 50px 80px 50px;
    }

    .memories__main__video {
        iframe {
            width: 600px;
            height: 400px;
        }
    }

    //  + Line up
    .twenty-two-lineup__main__content {
        margin: 0 20px;
    }
    .twenty-two-lineup__main__images {
        width: 100%;
    }
    .twenty-two-lineup__description__text {
        font-size: 28px;
        line-height: 38px;
    }

    //  + Sponsors
    .sponsors__image {
        &--first {
            margin-right: 0;
            width: 600px;
        }
        &--second {
            left: -180px;
            width: 700px;
        }
    }

    //  + After conf Footer
    .after-conf-footer__main {
        top: 130px;
    }
    .after-conf-footer__image {
        &--first {
            width: 500px;
        }

        &--second {
            width: 500px;
        }
    }

    //  + Hubspot form
    .sign_up__form {
        margin: 0 110px;
    }
}

// 1600px
@media (min-width: 1600px) {
    .header__main__content {
        padding-top: 125px;
    }
    .twenty-two__logo__img {
        &--second {
            margin-top: 60px;
            width: 100%;
        }
    }
    .twenty-two-header__main__countries__img {
        width: 51px;
        height: 31px;
    }

    // Button call to action

    //  + Line up
    .twenty-two-lineup__main__content {
        margin: 0;
    }
    .twenty-two-lineup__description__text {
        font-weight: normal;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: 0.14em;
        color: $white;
    }

    //  + Sponsors

    //  + Footer
    .footer-brand {
        padding: 30px 0;
        position: absolute;
        font-size: 14px;
    }
    .container {
        max-width: 95%;
    }

    //  + Hubspot form
    .sign_up__form {
        margin: 0;
    }
}

//1800px
@media(min-width: $breakpoint-xxl) {
    .header__main__content {
        padding-top: 130px;
        justify-content: space-between;
    }
    .twenty-two__logo__img {
        &--second {
            margin-top: 60px;
            width: 100%;
        }
    }
    .twenty-two-header__main__countries__img {
        width: 51px;
        height: 31px;
        margin-left: 5px;
    }

    //  + Speakers
    .first_speaker__main {
        .speakers-twentytwo__avatars__img {
            width: 328px;
        }
    }

    //  + Memories
    .memories__image {
        &--first {        
            padding-top: 230px;
            margin-left: 50px;
        }
        &--second {
            position: absolute;
            right: 0;
            top: -250px;
            width: 800px;
        }
    }
    .memories__main {
        margin: 450px 60px 150px 60px;
    }

    .memories__main__video {
        iframe {
            width: 800px;
            height: 500px;
        }
    }
}

.live-header__main {
    padding-top: 60px;
    @media(min-width: $breakpoint-md) {
        padding-top: 120px;
    }
}

.live-header-img__container {
    margin: auto 20px;
    max-width: 654px;

    @media(min-width: $breakpoint-md) {
        margin: auto auto 38px auto;
    }
}

.live__container {
    margin: 10px;
    
    iframe {
        width: 100%;
        height: 360px;
        &:nth-child(2) {
            @media(min-width: $breakpoint-xl) {
                height: 684px;
            }
        }

        @media(min-width: $breakpoint-xl) {
            height: 684px;

            &:nth-child(2) {
                flex: 35%;
            }
        }
    }

    @media(min-width: $breakpoint-md) {
        margin: 0 30px;
    }

    @media(min-width: $breakpoint-xl) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 40px;
    }
}