@import "../../global-styles/variables";

.out-txt__txt {
    font-weight: 900;
    &--base {
        fill: $white;
    }
    &--outline {
        fill: none;
        stroke: $gray-default;
        stroke-linejoin: round;
        stroke-width: 0.15;
    }
}

.out-txt {
    &--green {
        .out-txt__txt--base {
            fill: $green;
        }
    }
    &--orange {
        .out-txt__txt--base {
            fill: $orange;
        }
    }
    &--blue {
        .out-txt__txt--base {
            fill: $blue;
        }
    }
    //  +
    &--stroke-white {
        .out-txt__txt--outline {
            stroke: $white;
        }
    }
}
