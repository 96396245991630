@import "../../global-styles/variables";

/* BACKGROUND SETTINGS */
$circle-colors: #2c4981 #fcb83b #1a4cffaa #2cce93 #2c4981 #fcb83b #1a4cff97 #2cce93 #fcb83b
                #2c4981 #fcb83b #1a4bffb3 #2cce93 #2c4981 #fcb83b #1a4bffa5 #2cce93 #fcb83b
                #2c4981 #fcb83b #1a4bffb3 #2cce93 #2c4981 #fcb83b #1a4bffa5 #2cce93 #fcb83b;
$dots-quantity: 20;
$animation-delay: -30s;
$animation-duration: 50s;

@mixin dots($count) {
  $text-shadow: ();
  @for $i from 1 through $count {
    $text-shadow: $text-shadow,
                  (-1.5+(random()) * 3) + em
                  (-1.5+(random()) * 3) + em
                  7px
                  nth($list: $circle-colors, $n: $i);
  }
  text-shadow: $text-shadow;
}

.coming-soon__main-container {
  display: block;
  font-size: 52px;
  color: transparent;
  overflow-y: visible;
  &:before, &:after {
    position: fixed;
    top: 20%;
    left: 20%;
    width: 1.6em;
    height: 1.6em;
    content: '.';
    mix-blend-mode: screen;
    animation: $animation-duration $animation-delay move infinite ease-in-out alternate;
    z-index: 2;
  }
  &:before {
    @include dots($dots-quantity);
    animation-duration: $animation-duration;
    animation-delay: $animation-delay;
  }
  &:after {
    @include dots($dots-quantity);
    animation-duration: $animation-duration;
    animation-delay: -32s;
    top: 50%;
    left: 50%;
  }
}

/*
body::before {
  @include dots($dots-quantity);
  animation-duration: $animation-duration;
  animation-delay: $animation-delay;
}

body::after {
  @include dots($dots-quantity);
  animation-duration: $animation-duration;
  animation-delay: -32s;
  top: 50%;
  left: 50%;
}
*/
  
@keyframes move {
  from {
    transform: rotate(0deg) scale(40) translateX(-10px);
  }
  to {
    transform: rotate(360deg) scale(30) translateX(10px);
  }
}
/* END BACKGROUND SETTINGS */

.first__bg__dot {
  position: fixed;
  right: 0;
  top: -40px;
}
.second__bg__dot {
  position: fixed;
  left: 0;
  bottom: -5px;
}
.third__bg__dot {
  position: fixed;
  right: 0;
  bottom: -5px;
}

.coming-soon__main {
  padding-top: 5vh;
  padding-bottom: 2vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  z-index: 3;

  .little-circle {
    display: none;
    position: absolute;

    transition: all infinite linear;
    animation: shake 5s linear infinite;

    &:first-child {
      left: 20vw;
      top: 12vh;
    }
    &:nth-child(2) {
      left: 16vw;
      top: 13vh;
      animation: shakeTwo 5s linear infinite;
    }
    &:nth-child(3) {
      left: 19vw;
      top: 18vh;
      animation: shakeThree 5s linear infinite;
    }
    &:nth-child(4) {
      right: 19vw;
      bottom: 18vh;
      animation: shakeTwo 5s linear infinite;
    }
    @media (min-width: $breakpoint-xl) {
      display: block;
    }
  }
  .coming-soon__logo {
    min-width: 210px;
    width: 210px;
    margin-bottom: 10vh;
    z-index: 3;
    @media (min-width: $breakpoint-md) {
      margin-bottom: 7vh;
      width: 360px;
    }
    @media (min-width: $breakpoint-lg) {
      margin-bottom: 5vh;
      width: 280px;
    }
    @media (min-width: $breakpoint-xxl) {
      width: 360px;
    }
  }
  .coming-soon-container {
    width: 100%;
    text-align: center;
    z-index: 3;
  }
  .coming-soon__revolution {
    &--img {
      margin-bottom: 10px;
      min-width: 210px;
      width: 310px;
      margin-left: 10px;

      @media (min-width: $breakpoint-md) {
        margin-bottom: 15px;
        width: 470px;
        margin-left: 0;
      }
      @media (min-width: $breakpoint-lg) {
        margin-bottom: 10px;
        width: 430px;
      }
      @media (min-width: $breakpoint-xxl) {
        width: 570px;
      }
    }
  }
  .coming-soon__fintech {
    &--img {
      min-width: 210px;
      width: 310px;

      @media (min-width: $breakpoint-md) {
        margin-bottom: 15px;
        width: 480px;
      }
      @media (min-width: $breakpoint-lg) {
        margin-bottom: 10px;
        width: 430px;
      }
      @media (min-width: $breakpoint-xxl) {
        margin-bottom: 0;
        width: 580px;
      }
    }

    @media (min-width: $breakpoint-md) {
      margin-right: 25px;
    }
    @media (min-width: $breakpoint-lg) {
      margin-right: 20px;
    }
    @media (min-width: $breakpoint-xxl) {
      margin-right: 32px;
    }
  }
  .coming-soon__img {
    margin-bottom: 7vh;
    &--img {
      min-width: 210px;
      width: 305px;
      @media (min-width: $breakpoint-md) {
        width: 460px;
      }
      @media (min-width: $breakpoint-lg) {
        width: 415px;
      }
      @media (min-width: $breakpoint-xxl) {
        width: 555px;
      }
    }
    @media (min-width: $breakpoint-md) {
      margin-bottom: 6vh;
      margin-right: 38px;
    }
    @media (min-width: $breakpoint-lg) {
      margin-bottom: 6vh;
      margin-right: 38px;
    }
    @media (min-width: $breakpoint-xxl) {
      margin-right: 60px;
      margin-top: 5px;
    }
  }
  .coming-soon__link {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 3;
  
    input {
      background-color: transparent;
      border: 3px solid #006CF9;
      border-radius: 50px;
      color: $white;
      font-size: 20px;
      line-height: 30px;
      height: 62px;
      padding-left: 16px;
      width: 300px;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $white;
        opacity: 1; /* Firefox */
      }

      &:active, &:focus, &:focus-visible {
        background-color: transparent;
        border: 0;
        outline: none;
      }

      @media (min-width: $breakpoint-md) {
        padding-left: 8px;
        width: 280px;
        border: 0px solid transparent;
        margin-left: 20px;
      }
      @media (min-width: $breakpoint-lg) {
        width: 243px;
        height: 80px;
      }
      @media (min-width: $breakpoint-xxl) {
        width: 325px;
      }
    }
    &--button {
      width: 300px;
      height: 62px;
      background-color: #006CF9;
      border: 1px solid #006CF9;
      border-radius: 69.6059px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-wrap: nowrap;
      flex-direction: row;
      cursor: pointer;
      transition:  .4s all;

      span {
        font-weight: 900;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1em;
        color: $white;

        @media (min-width: $breakpoint-lg) {
          font-size: 16px;
          line-height: 24px;
        }
        @media (min-width: $breakpoint-xxl) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      &:hover {
        background-color: $white;
        border-color: $white;
        
        span {
          color: #17D3CD;
        }
      }

      @media (min-width: $breakpoint-md) {
        width: 200px;
        height: 80px;
      }
      
      @media (min-width: $breakpoint-lg) {
        width: 190px;
      }
      @media (min-width: $breakpoint-xxl) {
        width: 220px;
      }
    }

    &--completed {
      border: 0;
    }
    .success-message {
      font-size: 24px;
      color: $white;
    }

    @media (min-width: $breakpoint-md) {
      border: 3px solid #006CF9;
      border-radius: 50px;
      flex-direction: row;
      gap: 0;

      &--completed {
        border: 0;
      }
    }
    @media (min-width: $breakpoint-xxl) {
      margin-right: 40px;
    }
  }
  .little-circle-two {
    display: none;
    position: absolute;
    right: 10vw;
    top: 35vh;
    @media (min-width: $breakpoint-xl) {
      display: block;

      transition: all infinite linear;
      animation: shakeTwo 5s linear infinite;
    }
  }
  .little-circle-three {
    display: none;
    position: absolute;
    right: 15vw;
    top: 40vh;
    @media (min-width: $breakpoint-xl) {
      display: block;

      transition: all infinite linear;
      animation: shake 5s linear infinite;
    }
  }
}

.coming-soon-description {
  margin-top: 40px;
  max-width: 465px;
  text-align: center;
  padding: 0 20px;
  z-index: 3;

  p {
    color: $white;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }

  @media (min-width: $breakpoint-md) {
    padding: 0;
  }
}
.mt-4{
  margin-top: 30px !important;
}
.center-flex{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.opacity-logo{
  opacity: 0;
}
.footer-brand {
  &--from-home {
      background-color: transparent;
      box-shadow: inherit;
      bottom: 0;
      position: static;
      width: 100%;
      text-transform: capitalize;
      p {
          color: $white;
      }
      .container {
          justify-content: center;
      }

    @media (min-width: $breakpoint-md) {
      position: absolute;
    }
  }
}

@keyframes shake {
  0% { transform: translate(2px, 1px) rotate(0deg); }
	10% { transform: translate(-1px, -2px) rotate(-1deg); }
	20% { transform: translate(-3px, 0px) rotate(1deg); }
	30% { transform: translate(0px, 2px) rotate(0deg); }
	40% { transform: translate(1px, -1px) rotate(1deg); }
	50% { transform: translate(-1px, 2px) rotate(-1deg); }
	60% { transform: translate(-3px, 1px) rotate(0deg); }
	70% { transform: translate(2px, 1px) rotate(-1deg); }
	80% { transform: translate(-3px, 0px) rotate(1deg); }
	90% { transform: translate(-1px, -2px) rotate(0deg); }
	100% {transform: translate(2px, 1px) rotate(-1deg); }
}

@keyframes shakeTwo {
  0% { transform: translate(-1px, -2px) rotate(0deg); }
	10% { transform: translate(-2px, -3px) rotate(-1deg); }
	20% { transform: translate(0px, -4px) rotate(1deg); }
	30% { transform: translate(2px, 0px) rotate(0deg); }
	40% { transform: translate(4px, 6px) rotate(1deg); }
	50% { transform: translate(0px, 0px) rotate(-1deg); }
	60% { transform: translate(4px, 6px) rotate(0deg); }
	70% { transform: translate(2px, 0px) rotate(-1deg); }
	80% { transform: translate(0px, -4px) rotate(1deg); }
	90% { transform: translate(-2px, -3px) rotate(0deg); }
	100% {transform: translate(-1px, -2px) rotate(-1deg); }
}

@keyframes shakeThree {
  0% { transform: translate(6px, 7px) rotate(0deg); }
	10% { transform: translate(-1px, -3px) rotate(-1deg); }
	20% { transform: translate(3px, -4px) rotate(1deg); }
	30% { transform: translate(3px, 1px) rotate(0deg); }
	40% { transform: translate(0px, 2px) rotate(1deg); }
	50% { transform: translate(0px, 0px) rotate(-1deg); }
	60% { transform: translate(2px, -2px) rotate(0deg); }
	70% { transform: translate(2px, 3px) rotate(-1deg); }
	80% { transform: translate(1px, -4px) rotate(1deg); }
	90% { transform: translate(-1px, -3px) rotate(0deg); }
	100% {transform: translate(6px, 7px) rotate(-1deg); }
}