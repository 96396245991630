@import "../../global-styles/variables";

/// INDEX
//  + General
//  + Description
//  + Speakers
//  + Line up
//  + Sponsors
//  + After conf Footer
//  + Footer
//  [ Responsive ]

//------------------------------
//  + General
.home {
    background: linear-gradient(250.22deg, $violet 0%, #3A42B6 51.7%, $blue 100.27%);
    z-index: 3;
}

.circle__header {
    position: absolute;
    width: 200px;
    &--first {
        right: 0px;
    }
    &--second {
        display: none;
        width: 250px;
        right: 320px;
    }
    &--third {
        display: none;
        width: 200px;
        left: 0;
        top: 60px;
    }
    &--fourth {
        display: none;
        width: 100px;
        left: 120px;
        top: 100px;
    }
    &--seventh {
        display: none
    }
}

.home__background {
    display: none;
}

.home__container {
    background: transparent;
    max-width: 1600px;
    margin: auto;
    position: relative;
    width: 100%;
    z-index: 2;
}

.header__main {
    &--after-conf {
        height: 100vh;
    }
}
.header__main__content {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.header__main__title {
    position: absolute;
    width: 100%;
    top: -60px;
    text-align: center;
    &--section {
        color: $white;
        font-weight: 900;
        font-size: 46px;
    }
}

.header__main__left-side {
    position: relative;
    width: 100%;
}


.header__main__right-side {
    text-align: center;
}

.header__main__img {
    width: 80%;

    &--after-conf {
        width: 90%;
    }
}

.header__main__countries {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    gap: 20px;
    margin-top: 63px;
    margin-bottom: 10px;
}

.header__main__countries__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.header__main__countries__label {
    color: $white;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.14em;
}

.header__main__countries__img {
    width: 20px;
    height: 15px;
    margin-left: 2px;
}

.home__main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.home__logo__main {
    margin-top: 65px;
    margin-bottom: 30px;
}

.home__logo__img {
    width: 216px;
    &--first {
        display: none;
        margin-top: 10px;
        margin-left: 10px;
        width: 150px;
    }
    &--second {
        width: 250px;
    }
}

// CONFERENCE IMAGE
.conf__img {
    width: 261px;
    height: 156px;
}

.conf__img__main {
    margin-bottom: 21px;
}

//  + Countdown timer
.countdown_timer__main {
    margin: 0 15px;
}
.countdown__main {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: auto;
    max-width: 1400px;
    gap: 0;
    @media (min-width: $breakpoint-xl) {
        gap: 40px;
    }
}

.countdown__number {
    font-weight: bold;
    font-size: 42px;
    line-height: 51px;
    text-align: center;
    color: $white;
    @media (min-width: $breakpoint-md) {
        font-size: 90px;
        line-height: 60px;
    }
    @media (min-width: $breakpoint-xl) {
        font-weight: bold;
        font-size: 90px;
        line-height: 60px;
    }
    @media (min-width: $breakpoint-xxl) {
        font-size: 128px;
        line-height: 135px;
    }
    
}

.countdown__text {
    font-size: 11px;
    line-height: 8px;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
    @media (min-width: $breakpoint-md) {
        font-size: 28px;
        line-height: 60px;
    }
    @media (min-width: $breakpoint-xxl) {
        font-size: 23px;
        line-height: 28px;
    }
    
}

.colon {
    align-self: flex-start;
    font-size: 60px;
    line-height: 18px;
    text-align: center;
    color: $white;
    margin: 0 10px;

    @media (min-width: $breakpoint-md) {
        font-size: 115px;
        line-height: 0px;
    }
    @media (min-width: $breakpoint-xxl) {
        font-size: 190px;
        line-height: 23px;
    }
}

//  + Button Call to action
.button__container {
    background: linear-gradient(213.81deg, $blue 13.25%, $green 100%);
    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
    border-radius: 120px;    
    width: 250px;
    margin: 20px auto;
    cursor: pointer;
    transition: 0.3s all ease-in;
    &:hover {
        box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.6);
    }
}

.button__label {
    color: $white;
    font-weight: 900;
    font-size: 15px;
    line-height: 45px;
    letter-spacing: 0.1em;
    padding: 0;
    text-align: center;
}

.description__content {
    background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.1) 100%);
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(42px);
    border-radius: 50px;
    margin: 0 20px;
}

.empty__space {
    background-color: transparent;
    height: 150px;
    width: 100%;
    &--first {
        position: relative;
    }
}

// + Description
.description__text {
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: $white;

    &--conf {
        padding: 40px 20px;
        margin: 0;
    }
    
    &--speakers {
        margin: 25px auto;
    }
}

//  + Speakers
.circle__speakers {
    display: none;
}

.speakers__content {
    background: radial-gradient(100% 100% at 0% 0%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.1) 100%);
    box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(42px);
    border-radius: 50px;
    padding-bottom: 40px;
    margin: 0 20px;
}

.speakers__main__images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    height: 0px;
    position: relative;
    top: -15px;
    z-index: 1;
}

.speakers__image {
    width: 150px;
    &--first {
        position: absolute;
        left: -20px;
        width: 150px;
    }
    &--second {
        position: absolute;
        right: 0px;
        width: 150px;
    }
}

.first_speaker__main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 100px 20px;
    gap: 10px;
    position: relative;
    z-index: 5;
}

.speakers__avatars__img {
    width: 150px;
}

.speakers__avatars__item {
    text-align: center;
}

.speakers__avatars__label {
    color: $white;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;    
    text-align: center;
}

.speakers__avatars__description {
    color: $white;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;    
    text-align: center;
    font-style: italic;
    margin-top: 5px;
}

.speakers__mobile {
    display: block;
}

.speakers__avatars__main {
    display: none;
}

//  + Memories
.memories__image {
    &--first {
        width: 150px;
        padding-top: 80px;
    }
    &--second {
        position: absolute;
        right: -20px;
        width: 200px;
    }
}

.memories__main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: row dense;
    grid-gap: 40px;
    text-align: center;
    margin: 100px 40px 40px 40px;

    iframe {
        width: 200px;
        height: 200px;
    }
}

//  + Line up
.lineup__container__main {
    position: relative;
}

.lineup__main__content {
    margin: 0 20px;
}

.lineup__main__images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.lineup__image {
    &--first {
        width: 150px;
        padding-top: 80px;
    }
    &--second {
        position: absolute;
        right: -20px;
        width: 200px;
    }
}

.lineup__main {
    text-align: center;
    margin-top: 50px;
}

.lineup__content {
    max-width: 1600px;
}

.lineup__description__main {
    border: 2px solid #17D3CD;
    margin-top: 35px;
    padding: 34px 21px;
    position: relative;
}

.lineup__description__text {
    font-size: 21px;
    line-height: 36px;
    letter-spacing: 0.14em;
    color: $white;
}

.lineup__timeline__main {
    border-left-width: 2px;
    border-right-width: 0;
    border-style: solid;
    border-image: 
    linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.4), 
        rgba(0, 0, 0, 0)
        ) 15% 100%;
    margin-left: 0px;
    position: relative;
}

.lineup__timeline__plus_img {
    display: none;
}

.lineup__timeline__item {
    text-align: start;
    margin-left: 14px;
    margin-top: 20px;
    position: relative;

    &::before {
        content: '';
        background-color: $white;
        clip-path: circle(50% at 50% 50%);
        width: 10px;
        height: 10px;
        position: absolute;
        left: -19px;
        top: 6px;
    }

    &:first-child {
        margin-top: 0;
        padding-top: 20px;

        &::before {
            top: 26px;
        }
    }

    &:last-child {
        margin-top: 0;
        padding-top: 20px;

        &::before {
            top: 26px;
        }
    }
}

.lineup__item__title {
    color: $white;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;    
    letter-spacing: 0.05em;
}

.lineup__item__help_text {
    color: $white;
    font-weight: 300;
    font-size: 9px;
    line-height: 18px;    
    letter-spacing: 0.14em;
}

//  + Sponsors
.sponsors__main__images {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.sponsors__image {
    &--first {
        align-self: center;
        width: 300px;
    }
    &--second {
        position: relative;
        left: -40px;
        width: 300px;
    }
}

.sponsors__main {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row dense;
    grid-gap: 20px;
    text-align: center;
    margin-top: 50px;
}

.sponsors__content {
    max-width: 1600px;
}

.sponsors__img {
    margin: auto;
    width: 150px;

    &--inkasur {
        width: 70px;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 1640px;
    padding: 0 30px;
}

//  + Footer
.footer-brand {
    &--from-home {
        background-color: transparent;
        box-shadow: inherit;
        bottom: 0;
        position: absolute;
        width: 100%;
        text-transform: capitalize;
        p {
            color: $white;
        }
        .container {
            justify-content: center;
        }
    }
}

//  + Hubspot form
.hubspot__form {
    max-width: 900px;
    margin: auto;
    position: relative;
    z-index: 2;
}

.hubspot__form__title {
    color: $white;
    font-weight: 900;
    font-size: 36px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

//  + Hubspot form
.sign_up__form {
    margin: 0 20px;
}

.circle__footer {
    display: none;
}

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
    // + General
    .circle__header {
        position: absolute;
        width: 200px;
        &--seventh {
            display: block;
            width: 500px;
            right: 120px;
            top: 60px;
        }
    }

    .background__mobile {
        display: none;
    }
    .home__background {
        display: block;
        height: 100vh;
        position: absolute;
        width: 100%;
    }
    .home__logo__img {
        &--first {
            position: absolute;
            margin-top: 20px;
            margin-left: 30px;
            width: 200px;
        }
        &--second {
            width: 70%;
            
            &--after-conf {
                width: 350px;
            }
        }
    }

    .header__main {
        &--after-conf {
            height: 100vh;
        }
    }

    .header__main__content {
        padding-top: 130px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    
    .header__main__img {
        width: 70%;

        &--after-conf {
            width: 100%;
        }
    }
    
    .header__main__countries {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        gap: 7px;
    }
    
    .header__main__countries__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: center;
    }
    
    .header__main__countries__label {
        color: $white;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.14em;
    }
    
    .header__main__countries__img {
        width: 34px;
        height: 24px;
        margin-left: 1px;
    }

    .header__main__left-side {    
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
        padding-top: 20px;
    }

    .header__main__title {
        position: initial;
        margin-top: 60px;
        text-align: center;
        &--section {
            font-size: 64px;
            line-height: 70px;
            margin-top: 20px;
        }
    }

    .home__logo__main {
        margin-top: 0;
    }

    //  Button call to action
    .button__container {
        width: 450px;
    }

    .button__label {
        font-size: 20px;
        line-height: 50px;
    }

    //  + Description
    .description__content {
        margin: 0 70px;
    }

    .description__text {
        &--conf {
            margin: 50px 30px;
            padding: 60px 20px;
        }

        &--speakers {
            padding: 0 30px;
        }
    }

    .empty__space {
        height: 250px;

        &--third {
            height: 160px;
        }
    }

    //  + Speakers
    .speakers__content {
        margin: 0 70px;
        padding-bottom: 50px;
    }

    .speakers__image {
        &--first {
            left: -70px;
            width: 340px; 
        }
        &--second {
            width: 330px;
            margin-right: 20px;
        }
    }

    .first_speaker__main {
        padding: 125px 20px;
    }

    .speakers__avatars__img {
        width: 220px;
    }

    .speakers__mobile {
        display: none;
    }

    .speakers__avatars__main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 40px;
        text-align: center;
    }
    
    //  + Memories
    .memories__image {
        width: 150px;
        &--first {
            position: relative;
            width: 300px;
            margin-left: 40px;
        }
        &--second {
            right: -70px;
            width: 350px;
        }
    }

    .memories__mobile {
        display: none;
    }

    .after-conf-footer__main {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    //  + After conf Footer
    .after-conf-footer__main {
        position: relative;
        width: 100%;
        top: 90px;
    }
    
    .after-conf-footer__image {
        &--first {
            width: 350px;
            position: absolute;
            left: -65px;
        }

        &--second {
            width: 350px;
            position: absolute;
            right: 0;
        }
    }

    .memories__main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-flow: row dense;
        grid-gap: 40px;
        text-align: center;
        margin: 180px 40px 40px 40px;
    }

    .memories__main__video {
        iframe {
            width: 400px;
            height: 200px;
        }
    }

    //  + Line up
    .lineup__main__content {
        margin: 0 70px;
    }

    .lineup__image {
        width: 150px;
        &--first {
            position: relative;
            width: 300px;
        }
        &--second {
            right: -70px;
            width: 400px;
        }
    }

    .lineup__timeline__plus_img {
        display: block;
        position: absolute;
        top: 150px;
        right: 80px;
        width: 300px;
        height: 300px;
    }

    .lineup__timeline__item {
        margin-left: 24px;
        margin-top: 32px;
    
        &::before {
            width: 15px;
            height: 15px;
            left: -32px;
            top: 6px;
        }
    
        &:first-child {
            margin-top: 0;
            padding-top: 32px;
    
            &::before {
                top: 37px;
            }
        }
    
        &:last-child {
            margin-top: 0;
            padding-top: 32px;
    
            &::before {
                top: 37px;
            }
        }
    }

    //  + Sponsors
    .sponsors__content {
        margin: 0 70px;
    }
    .sponsors__main__images {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
    }

    .sponsors__image {
        width: 150px;
        &--first {
            width: 350px;
        }
        &--second {
            left: -90px;
            width: 400px;
        }
    }

    .sponsors__main {
        grid-template-columns: repeat(2, 1fr);
    }

    .sponsors__img {
        margin: auto;
        width: 200px;
        &--inkasur {
            width: 130px;
        }
    }

    //  + Hubspot form
    .sign_up__form {
        margin: 0 70px;
    }

    //  + Footer
    .footer-brand {
        padding: 20px 0;
        font-size: 14px;
    }

    .circle__footer {
        display: block;
        width: 450px;
        position: absolute;
        bottom: 0;
        right: 100px;
    }
}
// 1200px
@media (min-width: $breakpoint-xl) {
    //  + General
    .circle__header {
        position: absolute;
        width: 200px;
        &--first {
            display: block;
            right: 460px;
        }
        &--second {
            display: block;
            width: 250px;
            right: 320px;
        }
        &--third {
            display: block;
            width: 200px;
            left: 0;
            top: 60px;
        }
        &--fourth {
            display: block;
            width: 100px;
            left: 120px;
            top: 100px;
        }
        &--seventh {
            display: block;
            width: 800px;
            right: 120px;
            top: 60px;
        }
    }

    .home__logo__img {
        &--first {
            position: absolute;
            margin-left: 30px;
            margin-top: 13px;
            width: 250px;
        }
        &--second {
            width: 480px;
        }
    }

    .header__main__content {
        padding-top: 100px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    
    .header__main__img {
        width: 90%;

        &--after-conf {
            width: 480px;
        }
    }
    
    .header__main__countries {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 40px;
        margin-top: 10px;
    }
    
    .header__main__countries__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: center;
    }
    
    .header__main__countries__label {
        color: $white;
        font-weight: normal;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.14em;
    }
    
    .header__main__countries__img {
        width: 34px;
        height: 24px;
    }

    .header__main__left-side {    
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
        padding-top: 20px;
        flex: 1 0 50%;
    }

    .header__main__right-side {
        text-align: center;
        align-self: flex-start;
        flex: 1 0 50%;
    }
    .header__main__title {
        margin-top: 50px;
        text-align: center;
        &--section {
            color: $white;
            font-weight: 900;
            font-size: 85px;
            line-height: 90px;
        }
    }

    .home__logo__main {
        margin-top: 0;
    }

    //  + Conference image
    .conf__img {
        width: 100%;
        height: 100%;
    }

    //  + Button call to action
    .button__container {
        background: linear-gradient(213.81deg, $blue 13.25%, $green 100%);
        box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
        border-radius: 120px;    
        width: 100%;
        max-width: 548px;
        margin: 30px auto 0 auto;
        cursor: pointer;
        transition: 0.5s all ease-in;
    }
    .button__label {
        font-size: 28px;
        line-height: 45px;
        padding: 13px;
    }
    .empty__space {
        height: 550px;
        &--second {
            height: 390px;
        }

        &--third {
            height: 320px;
        }
    }

    //  + Description
    .description__text {
        font-weight: normal;
        font-size: 20px;
        line-height: 37px;
        letter-spacing: 0.14em;
        margin: 0 50px;
        &--speakers {
            padding: 0;
        }
    }

    //  + Speakers
    .circle__speakers {
        &--first {
            display: block;
            position: absolute;
            width: 600px;
            left: 0;
            bottom: -350px;
        }
        &--second {
            display: block;
            position: absolute;
            width: 400px;
            left: 330px;
            bottom: 100px;
        }
    }
    
    .speakers__main__images {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        height: 0px;
        position: relative;
        top: -53px;
        z-index: 1;
    
    }
    
    .speakers__image {
        width: 100%;
        &--first {
            width: 600px;
        }
        &--second {
            width: 450px;
            margin-right: 80px;
        }
    }
    
    .first_speaker__main {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 200px 110px 100px 110px;
        gap: 50px;
        position: relative;
        z-index: 5;

        .speakers__avatars__img {
            margin-left: 10px;
            width: 280px;
        }
    }
    .speakers__avatars__img {
        width: 240px;
    }  
    .description__text {
        &--conf {
            margin-top: 70px;
            padding: 78px 140px;
        }
        &--speakers {
            margin: 25px auto;
        }
    }
    
    .speakers__avatars__label {
        font-weight: 800;
        font-size: 18px;
        line-height: 35px;
    }

    .speakers__avatars__description {
        font-weight: 500;
        font-size: 15px;
        line-height: 30px; 
        margin-top: 0;   
    }

    .speakers__avatars__main {
        grid-template-columns: repeat(3, 1fr);
        margin: auto 40px;
    }

    //  + Memories
    .memories__image {
        &--first {
            margin-left: 40px;
            width: 450px;
        }
        &--second {
            right: -70px;
            width: 600px;
        }
    }

    .memories__main {
        grid-template-columns: repeat(1, 1fr);
        margin: 300px 40px 50px 40px;
    }

    .memories__main__video {
        iframe {
            width: 600px;
            height: 400px;
        }
    }

    //  + After conf Footer
    .after-conf-footer__image {
        &--first {
            width: 400px;
        }

        &--second {
            width: 400px;
        }
    }

    //  + Line up 
    .lineup__container__main {
        position: relative;
        z-index: 1;
    }
    .lineup__image {
        &--first {
            margin-left: 0px;
            position: relative;
            width: 500px;
        }
        &--second {
            width: 600px;
        }
    }
    .lineup__description__main {
        margin-top: 66px;
        position: relative;
    }
    .lineup__description__text {
        font-weight: normal;
        font-size: 27px;
        line-height: 40px;
        letter-spacing: 0.14em;
        color: $white;
    }
    .lineup__timeline__main {
        margin-left: 30px;
    }

    .lineup__timeline__plus_img {
        top: 650px;
        right: 80px;
        width: 400px;
        height: 400px;
    }
    .lineup__timeline__item {
        margin-left: 36px;
        margin-top: 62px;
    
        &::before {
            width: 24px;
            height: 24px;
            left: -49px;
            top: 16px;
        }
    
        &:first-child {
            margin-top: 0;
            padding-top: 62px;
    
            &::before {
                top: 77px;
            }
        }
    
        &:last-child {
            margin-top: 0;
            padding-top: 62px;
    
            &::before {
                top: 77px;
            }
        }
    }
    .lineup__item__title {
        color: $white;
        font-weight: 800;
        font-size: 30px;
        line-height: 55px;    
        letter-spacing: 0.05em;
    }
    .lineup__item__help_text {
        color: $white;
        font-weight: 300;
        font-style: italic;
        font-size: 20px;
        line-height: 50px;    
        letter-spacing: 0.14em;
    }

    //  + Sponsors
    .sponsors__main__images {
        justify-content: center;
    }
    .sponsors__image {
        &--first {
            margin-right: 70px;
            width: 650px;
        }
        &--second {
            left: -100px;
            width: 600px;
        }
    }
    .sponsors__container__main {
        position: relative;
        z-index: 1;
    }
    .sponsors__main {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 10px;
    }

    //  + Footer
    .container {
        justify-content: center;
    }

    .hubspot__form__title {
        margin-top: 0;
        font-size: 50px;
    }

    .circle__footer {
        display: block;
        width: 450px;
        position: absolute;
        bottom: 0;
        right: 100px;
    }
}

//1400px
@media (min-width: $container-width) {
    //  + General
    .header__main {
        height: 80vh;
    }

    .header__main__content {
        padding-top: 100px;
    }
    .header__main__title {
        margin-top: 100px;
    }
    .header__main__img {
        width: 60%;

        &--after-conf {
            width: 550px;
        }
    }
    .home__logo__img {
        &--second {
            width: 600px;
        }
    }
    .header__main__title {
        &--section {
            font-size: 110px;
            line-height: 110px;
        }
    }
    .header__main__countries__label {
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.14em;
    }
    .header__main__countries__img {
        width: 34px;
        height: 24px;
    }

    //  + Countdown timer
    .countdown_timer__main {
        margin: 0 15px;
    }

    //  + Description
    .description__content {
        margin: 0 110px;
    }
    .description__text {
        &--conf {
            margin: auto;
            margin-top: 150px;
        }
        &--speakers {
            margin-left: 130px;
            align-self: flex-start;
            margin-top: 45px;
        }
    }

    //  + Speakers
    .speakers__content {
        margin: 0 110px;
        padding-bottom: 70px;
    }
    .speakers__image {
        &--first {
           left: -110px;
           width: 650px;
        }
    }

    .speakers__avatars__label {
        font-weight: 800;
        font-size: 20px;
        line-height: 50px;
    }
    
    .speakers__avatars__description {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px; 
        margin-top: 0;   
    }

    //  + Memories
    .memories__image {
        &--first {
            margin-left: 60px;
            width: 450px;
        }
        &--second {
            right: -110px;
            width: 700px;
        }
    }

    .memories__main {
        margin: 300px 50px 80px 50px;
    }

    .memories__main__video {
        iframe {
            width: 600px;
            height: 400px;
        }
    }

    //  + Line up
    .lineup__main__content {
        margin: 0 110px;
    }
    .lineup__main__images {
        width: 100%;
    }
    .lineup__image {
        &--first {
            margin-left: 0;
            width: 550px;
        }
        &--second {
            right: -110px;
            width: 700px;
        }
    }
    .lineup__description__text {
        font-size: 28px;
        line-height: 38px;
    }

    //  + Sponsors
    .sponsors__content {
        margin: 0 110px;
    }
    .sponsors__image {
        &--first {
            margin-right: 0;
            width: 600px;
        }
        &--second {
            left: -180px;
            width: 700px;
        }
    }

    //  + After conf Footer
    .after-conf-footer__main {
        top: 130px;
    }
    .after-conf-footer__image {
        &--first {
            width: 500px;
        }

        &--second {
            width: 500px;
        }
    }

    //  + Hubspot form
    .sign_up__form {
        margin: 0 110px;
    }
}

// 1600px
@media (min-width: 1600px) {

    //  + General
    .header__main {
        min-height: 80vh;
    }
    .header__main__content {
        padding-top: 125px;
    }
    .header__main__img {
        width: 100%;
    }
    .home__logo__img {
        &--second {
            margin-top: 60px;
            width: 100%;
        }
    }
    .header__main__title {
        &--section {
            font-size: 147px;
            line-height: 176px;
        }
    }
    .header__main__countries__label {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.14em;
    }
    .header__main__countries__img {
        width: 44px;
        height: 34px;
    }

    //  + Countdown timer
    .countdown_timer__main {
        margin: 35px 15px;
    }

    // Button call to action
    .button__container {
        margin: 30px auto 0 auto;
    }
    .button__label {
        font-size: 28px;
        line-height: 60px;
        padding: 10px;
    }

    //  + Description
    .description__content {
        margin: 0;
    }

    .empty__space {
        height: 550px;
        &--second {
            height: 100px;
        }

        &--third {
            height: 350px;
        }
    }

    //  + Speakers
    .speakers__content {
        margin: 0;
        padding-bottom: 80px;
    }
    .speakers__image {
        &--first {
            position: initial;
        }
        &--second {
            position: initial;
            right: 0px
        }
    }

    //  + Line up
    .lineup__main__content {
        margin: 0;
    }
    .lineup__timeline__main {
        margin-left: 90px;
    }
    .lineup__description__text {
        font-weight: normal;
        font-size: 36px;
        line-height: 48px;
        letter-spacing: 0.14em;
        color: $white;
    }

    //  + Sponsors
    .sponsors__content {
        margin: 0;
        margin-bottom: 40px;
        padding-bottom: 80px;
    }

    //  + Footer
    .footer-brand {
        padding: 30px 0;
        position: absolute;
        font-size: 14px;
    }
    .container {
        max-width: 95%;
    }

    //  + Hubspot form
    .sign_up__form {
        margin: 0;
    }
}

//1800px
@media(min-width: $breakpoint-xxl) {
    //  + General
    .header__main {
        min-height: 80vh;
    }
    .header__main__content {
        padding-top: 130px;
        justify-content: space-between;
    }
    .header__main__img {
        width: 90%;

        &--after-conf {
            width: 800px;
        }
    }
    .home__logo__img {
        &--second {
            margin-top: 60px;
            width: 100%;
        }
    }
    .header__main__title {
        &--section {
            font-size: 140px;
            line-height: 176px;
        }
    }
    .header__main__countries__label {
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.14em;
    }
    .header__main__countries__img {
        width: 44px;
        height: 34px;
    }

    //  + Countdown timer
    .countdown_timer__main {
        margin: 0 15px 35px 15px;
    }

    .empty__space {
        height: 500px;
        &--second {
            height: 400px;
        }

        &--third {
            height: 260px;
        }
    }

    //  + Button call to action
    .button__container {
        height: 130px;
        text-align: center;
        margin: auto;
        max-width: 812px;
    }
    .button__label {
        font-size: 46px;
        line-height: 130px;
        padding: 0;
    }

    //  + Description
    .description__text {
        font-size: 23px;
        align-self: flex-start;
        &--conf {
            margin-top: 40px;
        }
        &--speakers {
            margin-left: 125px;
            margin-top: 40px;
        }
    }

    //  + Speakers
    .first_speaker__main {
        .speakers__avatars__img {
            width: 328px;
            margin-left: 37px;
        }
    }

    //  + Memories
    .memories__image {
        &--first {        
            padding-top: 230px;
            margin-left: 50px;
        }
        &--second {
            position: absolute;
            right: 0;
            top: -250px;
            width: 800px;
        }
    }
    .memories__main {
        margin: 450px 60px 150px 60px;
    }

    .memories__main__video {
        iframe {
            width: 800px;
            height: 500px;
        }
    }

    //  + Line up
    .lineup__image {
        &--first {        
            padding-top: 250px;
        }
        &--second {
            position: absolute;
            right: 0;
            top: -64px;
        }
    }
    .lineup__timeline__plus_img {
        top: 400px;
        right: 80px;
        width: 600px;
        height: 600px;
    }
}

//2100px
@media(min-width: 2100px) {
    .home__container {
        .header__main {
            min-height: 80vh;
        }
    }
}