@import "../../global-styles/variables";

.footer-brand {
  width: 100%;
  position: relative;
  padding: 25px 0;
  font-size: 9px;
  text-transform: uppercase;
  background-color: $white;
  box-shadow: 0px 1px 6px #00000029;
  z-index: $z-footer;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 1640px;
    padding: 0 30px;
    flex-direction: column;
    gap: 10px;
  }

  &--from-home {
    background-color: transparent;
  }
}

.footer-brand__content {
  display: flex;
  align-items: center;
}

.footer-brand__content__link {
  width: 65px;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }
}

.footer-brand__social {
  display: flex;
}

.footer-brand__social__item {
  width: 22px;
  height: 22px;
  background-color: $blue;
  border-radius: 50%;
  transition: background 0.25s ease-in-out;
  + .footer-brand__social__item {
    margin-left: 8px;
  }
  &:hover {
      background-color: $orange;
  }
}

.footer-brand__social__item__link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 14px;
  svg {
    * {
      fill: $white;
    }
  }
  &--facebook {
    padding: 7px 0 0 5px;
    font-size: 17px;
  }
}

//  [ Responsive ]
@media (min-width: $breakpoint-md) {
  .footer-brand {
    padding: 50px 0;
    font-size: 17px;
    .container {
      flex-direction: row;
      gap: 0;
    }
  }

  .footer-brand__content__link {
    width: 113px;
  }
  
  .footer-brand__content__link__logo {
    max-width: 113px;
    margin-left: 12px;
  }

  .footer-brand__social {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  .footer-brand__social__item {
    height: 36px;
    width: 36px;
  }

  .footer-brand__social__item__link {
    font-size: 24px;
    &--facebook {
      font-size: 26px;
      padding: 10px 0 0 5px;
    }
  }
}
// 1200px
@media (min-width: $breakpoint-xl) {
  .footer-brand {
    .container {
      justify-content: center;
    }
  }
}
// 1600px
@media (min-width: $breakpoint-xxl) {
  .footer-brand {
    .container {
      max-width: 95%;
      justify-content: center;
    }
  }
}
