@import "../../global-styles/variables";

.fl-txt {
  max-width: 400px;
}

.fl-txt__item {
  + .fl-txt__item {
    margin-top: 5px;
  }
}

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
  .fl-txt__item {
      display: block;
      max-width: 95%;
      min-width: 85%;
      width: 36vh;
      margin-left: auto;
    + .fl-txt__item {
      margin-top: 10px;
    }
  }
}


// 1600px
@media (min-width: $breakpoint-xxl) {
    .fl-txt__item {
        width: 400px;
        + .fl-txt__item {
            margin-top: 15px;
          }
    }
}
