@import "/global-styles/variables";

/// INDEX
//  + General
//  + By elements
//  + Custom Classes
//  + Animations
//      + Animations : Classes
//      + Animations : Keyframes
//  [ Responsive ]

//------------------------------

//  + General
html{
    overflow-x: hidden;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
    line-height: 1;
    color: $gray-default;
}

//------------------------------

//  + By elements
img {
    height: auto;
    width: 100%;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

//------------------------------

//  + Custom Classes
.page {
    margin: 0 auto;
    padding-top: 95px;
}

.btn {
    width: 100%;
    max-width: 340px;
    padding: 20px 12px;
    color: $gray-default;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    border-radius: 37px;
    transition: all 0.18s ease-in-out;
    &--cta {
        background-color: $green;
        color: $white;
        &:hover {
            background-color: $orange;
        }
    }
    &--light {
        background-color: $white;
        &:hover {
            color: $orange;
        }
    }
    &:hover {
        @extend .box-shadow;
        cursor: pointer;
    }
}

.container {
    width: 100%;
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 15px;
    @media (min-width: $breakpoint-md) {
        width: 90%;
    }
    @media (min-width: ($container-width+200px)) {
        width: 100%;
        padding: 0;
    }
}

//------------------------------

//  + Animations
//      + Animations : Classes
.shake-animation {
    > *:first-child {
        animation: shake_sm 0.45s ease-in-out 1;
    }
}

//      + Animations : Keyframes
@keyframes shake_sm {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(5deg);
    }
    40% {
        transform: rotate(-5deg);
    }
    60% {
        transform: rotate(5deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes shake_md {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(10deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    60% {
        transform: rotate(10deg);
    }
    80% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

//------------------------------

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
    .page {
        padding-top: 120px;
    }
}
