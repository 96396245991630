@import "../../global-styles/variables";

/// INDEX
//  + General
//      + Active: Variations
//  + Logo
//  + Header Container
//  + Hamburger
//  + Sub-menu
//  [ Responsive ]

//------------------------------

//  + General
.header {
    @extend .box-shadow;
    height: 60px;
    width: 100%;
    position: fixed;
    padding: 8px 0;
    background-color: $white;
    z-index: $z-header;
    transition: 0.25s all ease-in-out;
    //      + Active: Variations
    &.active {
        background-color: $orange;
        .header__container__logo {
            svg {
                * {
                    fill: $white;
                }
            }
        }
        .header__container__hamburguer {
            &::before,
            &::after,
            .bar__hide {
                background-color: $white;
            }
        }
        &.header--from-home {
            background: linear-gradient(250.22deg, #60268D 0%, #3A42B6 51.7%, #006CF4 100.27%);
        }
    }

    &--from-home {
        display: block;
        background-color: transparent;
        box-shadow: inherit;
        padding-bottom: 0;
        .header__container__hamburguer {
            &::before,
            &::after {
                background-color: $white;
            }
            &::before {
                top: 0;
            }
            &::after {
                bottom: 0;
            }
            &:hover {
                cursor: pointer;
            }
            .bar__hide {
                background-color: $white;
            }
        }

        &--up,
        &--down {
            background-color: $white;
            .header__container { 
                .header__container__menu__item__txt,
                .header__container__menu__item__arrow {
                    color: #4e4e4e;
                }
            }

            .header__container__hamburguer {
                &::before,
                &::after {
                    background-color: #4e4e4e;
                }
                .bar__hide {
                    background-color: #4e4e4e;
                }
            }
        }
    }

    &--home-2022 {
        background-color: #000000ad;

        .header__container__hamburguer {
            &::before,
            &::after {
                background-color: $white;
            }
            &::before {
                top: 0;
            }
            &::after {
                bottom: 0;
            }
            &:hover {
                cursor: pointer;
            }
            .bar__hide {
                background-color: $white;
            }
        }

        &--up,
        &--down {
            background-color: $white;
            .header__container { 
                .header__container__menu__item__txt,
                .header__container__menu__item__arrow {
                    color: #4e4e4e;
                }
            }

            .header__container__hamburguer {
                &::before,
                &::after {
                    background-color: #4e4e4e;
                }
                .bar__hide {
                    background-color: #4e4e4e;
                }
            }
        }

        .header__container__menu__item {
            .header__container__menu__item__txt,
            .header__container__menu__item__arrow {
                color: $white;
            }   
        }
    }
}

.header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 10px;
    max-width: 95%;
    height: 40px;

    &--from-home {
        justify-content: flex-end;
        &--logo {
            justify-content: space-between;
        }
        
        .header__container__menu__item__txt,
        .header__container__menu__item__arrow {
            color: $white;
        }
    }
}

.header__menu-item {
    font-size: 18px;
    font-weight: 900;
    text-align: left;
    text-transform: none;
    padding: 15px 0;
    &:hover {
        text-decoration: underline;
    }
    + .header__menu-item {
        border-top: 1px solid $gray-30;
    }
}

//  + Logo
.header__container__logo {
    width: 100%;
    max-width: 140px;

    &--home-2022 {
        max-width: 160px;
    }
    svg {
        * {
            fill: $orange;
        }
    }
    &:hover {
        cursor: pointer;
    }
}

//  + Header Container
.header__container__menu {
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 60%;
}

.header__container__menu__item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    + .header__container__menu__item {
        margin-left: 6.5%;
    }
    &--dropdown {
        &:hover {
            cursor: pointer;
            .header__container__menu__item__arrow {
                padding-bottom: 4px;
                transform: rotate(-180deg);
            }
            .header__container__menu__item__list {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.header__container__menu__item__txt {
    transition: color 0.25s ease-in-out;
    &:hover {
        cursor: pointer;
        color: $orange;
    }
}

.header__container__menu__item__arrow {
    width: 15px;
    margin-left: 14px;
}

.header__container__menu__item__list {
    @extend .box-shadow;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    padding: 20px;
    font-weight: 400;
    text-align: center;
    background-color: $white;
    border-radius: 12px;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    visibility: hidden;

    &--from-home {
        margin-top: 10px;
        padding: 10px 20px;
    }
}

//  + Hamburger
.header__container__hamburguer {
    display: block;
    position: relative;
    width: 27px;
    height: 18px;
    display: flex;
    align-items: center;
    &::before,
    &::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 5px;
        background-color: $gray-70;
    }
    &::before {
        top: 0;
    }
    &::after {
        bottom: 0;
    }
    &:hover {
        cursor: pointer;
    }
    .bar__hide {
        height: 2px;
        width: 100%;
        background-color: $gray-70;
        border-radius: 5px;
    }
}

//  + Sub-menu
.header--submenu {
    display: block;
    position: fixed;
    top: -100%;
    width: 100%;
    height: 100%;
    padding: 105px 30px;
    background-color: $white;
    transition: top 0.35s ease-in-out;
    visibility: visible;
    z-index: $z-submenu;
    &.active {
        top: 0%;
    }
    .header__menu-item {
        max-width: 190px;
        border-bottom: 2px solid $gray-30;
        + .header__menu-item {
            border-top: none;
        }
    }
}

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
    .header {
        height: 80px;
        &--from-home {
            display: block;
        }
    }
    .header__container {
        padding: 0 15px;
        height: 60px;
    }
    .header__container__logo {
        max-width: 185px;
        &--home-2022 {
            max-width: 230px;
        }
    }
    .header__container__menu {
        display: flex;
    }
    .header__container__hamburguer {
        display: none;
    }
    .header--submenu {
        display: none;
    }
}
// 1800px
@media (min-width: $breakpoint-xxl) {
    //  + Header Container
    .header__container {
        max-width: 1400px;
        justify-content: space-between;

        &--from-home {
            justify-content: flex-end;
            &--logo {
                justify-content: space-between;
            }
            
            .header__container__menu__item__txt,
            .header__container__menu__item__arrow {
                color: $white;
            }
        }
    }
    .header__container__menu {
        width: 40%;
    }

    .header__container__menu__item {
        + .header__container__menu__item {
            margin-left: 96px;
        }
    }
}

