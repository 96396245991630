@import "../../global-styles/variables";

/// INDEX
//  + General
//      + Variations: Color
//      + Variations: Classes
//  + Image
//  + Info
//  + Loading
//  [ Responsive ]

//------------------------------

//  + General
.swiper-slide-active {
    .spkr__img-box--wrapper {
        &::after {
            opacity: 1;
        }
    }
    .spkr__info__job {
        opacity: 1;
    }
    .spkr__info__role {
        opacity: 1;
    }
}

//      + Variations: Color
.spkr {
    overflow: hidden;
    .spkr__img-box--wrapper {
        &::after {
            background-color: $orange;
        }
    }
    .spkr__info__role {
        color: $orange;
    }
    &--green {
        .spkr__img-box--wrapper {
            &::after {
                background-color: $green;
            }
        }
        .spkr__info__role {
            color: $green;
        }
        .spkr__loading {
            color: $green;
        }
        .spkr__fl-loading {
            * {
                fill: $green;
            }
        }
    }
    &--blue {
        .spkr__img-box--wrapper {
            &::after {
                background-color: $blue;
            }
        }
        .spkr__info__role {
            color: $blue;
        }
        .spkr__loading {
            color: $blue;
        }
        .spkr__fl-loading {
            * {
                fill: $blue;
            }
        }
    }
    &--violet {
        .spkr__img-box--wrapper {
            &::after {
                background-color: $violet;
            }
        }
        .spkr__info__role {
            color: $violet;
        }
        .spkr__loading {
            color: $violet;
        }
        .spkr__fl-loading {
            * {
                fill: $violet;
            }
        }
    }

    //      + Variations: Classes
    &--whitespace-break {
        .spkr__info__job {
            white-space: pre-wrap;
            background-size: contain;
        }
    }
}

//  + Image
.spkr__img-box {
    position: relative;
}
.spkr__img-box--wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 24px;
    overflow: hidden;
    border-radius: 50%;
    max-height: 180px;
    max-width: 180px;
    height: 40vw;
    width: 40vw;
    &::after {
        content: "";
        position: absolute;
        top: 3px;
        left: calc(50% + 2px);
        max-height: 180px;
        max-width: 180px;
        height: 39.5vw;
        width: 39.5vw;
        border-radius: 50%;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity 0.25s ease-in;
        z-index: -1;
    }
    img {
        width: 115%;
        filter: grayscale(100%);
    }
}

//  + Info
.spkr__info {
    text-align: center;
}
.spkr__info__name {
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 900;
}
.spkr__info__job {
    position: relative;
    margin-bottom: 10px;
    max-width: 200px;
    margin: 0 auto 10px;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    white-space: nowrap;
}
.spkr__info__job__txt {
    color: $white;
    font-size: 10px;
    font-weight: 600;
}
.spkr__info__job__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.spkr__info__role {
    font-size: 15px;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

//  + Loading
$stroke-width: 8px;
.spkr--no-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-height: 180px;
    max-width: 180px;
    height: 40vw;
    width: 40vw;
    margin: 0 auto;
}
.spkr__fl-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.spkr__loading {
    font-size: 25px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
}

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
    //  + General
    .spkr {
        &:hover {
            .spkr__img-box--wrapper {
                &::after {
                    opacity: 1;
                }
            }
            .spkr__info__job {
                opacity: 1;
            }
            .spkr__info__role {
                opacity: 1;
            }
        }
    }
    //  + Image
    .spkr__img-box--wrapper {
        max-height: 250px;
        max-width: 250px;
        width: 14vw;
        height: 14vw;
        &::after {
            content: "";
            max-width: 250px;
            max-height: 250px;
            height: 14vw;
            width: 14vw;
        }
    }
    //  + Info
    .spkr__info__name {
        font-size: 25px;
        margin-bottom: 10px;
    }
    /*     .spkr__info__job {
        max-width: 200px;
        margin: 0 auto;
    } */
    .spkr__info__job__txt {
        font-size: 15px;
    }
    .spkr--no-content {
        max-height: 250px;
        max-width: 250px;
        height: 14vw;
        width: 14vw;
    }
    .spkr__loading {
        font-size: 1.9vw;
    }
}

// 1200px
@media (min-width: $breakpoint-xl) {
    .spkr__loading {
        font-size: 25px;
    }
}
