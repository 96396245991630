@import "../../global-styles/variables";

/// INDEX
//  + General
//  + View Mode : Basic
//  + Head
//  + Content
//  [ Responsive ]

//------------------------------

//  + General
.spkrs {
    padding: 80px 0;
    background-color: rgba($gray-30, 0.2);
    //  + View Mode : Basic
    &--basic {
        /// @see Speaker.scss
        .spkr__info__name {
            color: $blue;
        }
        .spkr__info__job,
        .spkr__info__role {
            padding: 0;
            opacity: 1;
        }
        .spkr__info__job__txt {
            color: $gray-default;
        }
        .spkr__info__job {
            white-space: normal;
            line-height: 1.2;
        }
        .spkr__info__job__img {
            display: none;
        }
        .spkr__img-box--wrapper {
            &:after {
                display: none;
            }
        }
    }
}

//  + Head
.spkrs__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 90px;
}

.spkrs__head__fl-img {
    display: none;
}

.spkrs__head__title {
    max-width: 220px;
    width: 100%;
    margin: 0 auto 10px;
}

.spkrs__head__txt {
    max-width: 90%;
    font-size: 15px;
    text-align: center;
}

//  + Content
.spkrs__content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.spkrs__content__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 55px 35px;
    justify-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
}
.spkrs__content__list__item {
    width: 100%;
}

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
    .spkrs {
        margin-bottom: 30px;
        padding: 160px 0;
    }
    .spkrs__head {
        align-items: flex-start;
        margin-bottom: 130px;
    }
    .spkrs__head__title {
        max-width: 545px;
        margin: 0 auto 30px 0;
    }
    .spkrs__head__txt {
        max-width: 805px;
        font-size: 20px;
        line-height: 25px;
        text-align: left;
    }
}

// 960px
@media (min-width: $breakpoint-lg) {
    .spkrs__head__fl-img {
        display: block;
        transform: scaleX(-1);
        position: absolute;
        top: -50%;
        right: 0;
        max-width: 527px;
        width: 25%;
        z-index: 3;
    }
    .spkrs__content__list {
        grid-template-columns: repeat(4, 1fr);
        max-width: 1600px;
    }
}

// 1200px
@media (min-width: $breakpoint-xl) {
    .spkrs__head {
        flex-direction: row;
    }
    .spkrs__head__title {
        margin-bottom: 0;
    }
    .spkrs__head__fl-img {
        width: 38%;
        top: -85%;
        right: -4.5%;
    }
}
