/// INDEX
//  + Colors
//  + Breakpoints
//  + Z-index
//  + Fonts
//  + Classes

//------------------------------
//  + Colors
$orange: #ffbb3c;
$blue: #046cfc;
$black: #000;
$white: #fff;
$gray-default: #4e4e4e;
$gray-80: #868686;
$gray-70: #707070;
$gray-30: #d7d7d7;
$orange-chrome: #ffa600;
$green: #00d6d0;
$violet: #520088;

//  + Breakpoints
$breakpoint-xsm: 480px;
$breakpoint-sm: 600px;
$breakpoint-md: 768px;
$breakpoint-lg: 960px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1800px;

$container-width: 1400px;

//  + Z-index
$z-header: 999;
$z-footer: $z-header;
$z-submenu: 998;

//  + Fonts
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@200;300;400;600;700;900&display=swap");

//  + Classes
.box-shadow {
    box-shadow: 0px 4px 6px #00000029;
}

.text-outline {
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}
