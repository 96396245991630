@import "../../global-styles/variables";

/// INDEX
//  + General
//      + General : Floating Elements
//  + Swiper
//  + Grid
//  [ Responsive ]

//------------------------------

//  + General
.mrs {
    position: relative;
    padding: 30px 0 90px;

    //      + General : Floating Elements
    .container {
      flex-direction: column;
      .fl-txt {
        margin: 0 0 -55px -15px;
      }
      .fl-txt__item {
          margin-left: 0;
      }
      .swiper-container {
        width: 100%;
      }
    }
}
.mrs__fl-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}
.mrs__fl-line__img {
    transform: rotate(4deg);
}

//  + Swiper
.swiper-slide-active {
    .mrs__swiper__item {
        &::after {
            display: none;
        }
    }
}
.mrs__swiper__item {
    display: flex;
    justify-content: center;
    position: relative;
    background-color: $gray-30;
    overflow: hidden;
    iframe {
        width: 100%;
    }
    &::before {
        content: "";
        display: block;
        padding-top: 56%;
    }
    &::after {
        // Avoids swiper js issue
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}

//  + Grid
.mrs__grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
}
.mrs__grid__item {
    width: 100%;
    background-color: $gray-30;
    overflow: hidden;
    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
    &:nth-child(5) {
        grid-column: 1 / 3;
        grid-row: 3 / 5;
    }
}

.conference_memories__gallery {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row dense;
    grid-gap: 20px;
    text-align: center;
  }
  .conference_memories__gallery__video {
    z-index: 3;
    max-width: 100%;
    height: 50%;
  
    iframe {
      width: 250px;
      height: 250px;
    }
  }

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
    //  + General
    .mrs {
        padding-bottom: 150px;
        flex-direction: row;
        //      + General : Floating Elements
        .fl-txt {
            width: 100%;    
            position: absolute;
            top: -63px;
            left: 60px;
        }
    }
    .mrs__fl-line {
        bottom: 40px;
    }
    .container {
        width: 100%;
    }
    //  + Swiper
    //  + Grid
    .conference_memories__gallery {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        iframe {
          width: 230px;
          height: 230px;
        }
    
        .conference_memories__gallery__video--3 {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
          iframe {
            width: 470px;
            height: 480px;
          }
        }
      }
      .conference_memories__gallery__video {
        z-index: 3;
        max-width: 100%;
        height: 100%;
      }
}

// 906px
@media (min-width: $breakpoint-lg) {
    //  + General
    .mrs {
        //      + General : Floating Elements
        .fl-txt {
        }
    }
    .mrs__fl-line {
        bottom: -30px;
    }
    //  + Swiper

    //  + Grid
    .mrs__grid {
        grid-template-columns: repeat(5, 1fr);
    }
    .mrs__grid__item {
        &:nth-child(5) {
            grid-column: 3 / 5;
            grid-row: 1 / 3;
        }
    }
}

// 1200px
@media (min-width: $breakpoint-xl) {
    //  + Speakers
    .conference_speakers__gallery {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 80px;
    }
    //  + Memories
    .conference_memories__gallery {
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
      iframe {
        width: 200px;
        height: 200px;
      }
  
      .conference_memories__gallery__video--3 {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
  
        iframe {
          width: 300px;
          height: 300px;
        }
      }
    }
    //  + Sponsors
    .conference_sponsors__gallery {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  // 1200px
@media (min-width: $breakpoint-xxl) {
    //  + Speakers
    .conference_speakers__gallery {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 80px;
    }
    //  + Memories
    .conference_memories__gallery {
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 20px;
      iframe {
        width: 300px;
        height: 300px;
      }
  
      .conference_memories__gallery__video--3 {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
  
        iframe {
          width: 300px;
          height: 300px;
        }
      }
    }
    //  + Sponsors
    .conference_sponsors__gallery {
      grid-template-columns: repeat(5, 1fr);
    }
}
