@import "../../global-styles/variables";

/// INDEX
//  + General
//      + Color Variations
//  + Header
//  + Title
//  + Speakers
//  + Gallery
//  + Footer
//  [ Responsive ]

//------------------------------

//  + General
.next-ed {
    //      + Color Variations
    &--orange {
        .next-ed__title__box__content__hglg {
            color: $orange;
        }
    }
    &--blue {
        .next-ed__title__box__content__hglg {
            color: $blue;
        }
    }
}

//  + Header
.next-ed__header {
    font-weight: 900;
    text-transform: uppercase;
    .container {
        display: flex;
        flex-direction: column-reverse;
        width: 95%;
        text-align: center;
    }
    .out-txt {
        display: block;
        font-size: 1rem;
        margin: 0 auto;
    }
}

.next-ed__header__actual {
    margin-top: 20px;
    font-size: 25px;
    font-weight: 900;
    .out-txt {
        width: 246px;
    }
}
.next-ed__header__next {
    display: block;
    font-size: 15px;
    font-weight: 900;
    text-align: right;
    position: relative;
    padding-right: 20px;
    .out-txt {
        width: 80px;
        margin-right: 0;
    }
    &::after {
        content: "";
        position: absolute;
        top: 49%;
        right: 0;
        transform: translateY(-50%);
        background-image: url("../../res/img/arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(-90deg);
        width: 15px;
        height: 7px;
    }
}

//  + Title
.next-ed__title {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    margin: 50px 0 80px;
}

.next-ed__title___img-bg {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: -1;
    transform: translateY(-50%);
}

.next-ed__title__box {
    @extend .box-shadow;
    background-color: $white;
}

.next-ed__title__box__content {
    padding: 20px 30px;
    text-transform: uppercase;
    font-size: 20px;
}

.next-ed__title__box__content__hglg {
    display: block;
    margin-top: 10px;
    font-weight: 900;
    white-space: nowrap;
    font-size: 24px;
}

//  + Speakers
.next-ed__spkr {
    margin-bottom: 60px;
    .fl-txt {
        margin: 0 auto -40px;
    }
    .spkrs {
        margin-bottom: 0;
    }
    .spkrs__content__list {
        max-width: 1400px;
        width: 95%;
    }
}

//  + Gallery
.next-ed__gallery {
    margin-bottom: 60px;
    .fl-txt {
        margin: 0 auto -35px;
    }
}

//  + Footer
.next-ed__footer {
    position: relative;
    padding: 50px 0;
    background-color: transparent;
}

.next-ed__footer__fl-bg {
    position: absolute;
    width: 100%;
    height: 110%;
    overflow: hidden;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.next-ed__footer__fl-bg__fl-img {
    position: absolute;
    bottom: 0%;
    left: 0;
    width: 1600px;
}

.next-ed__footer__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.next-ed__footer__list__item {
    color: $white;
    font-weight: 900;
    text-transform: uppercase;
    + .next-ed__footer__list__item {
        margin-top: 75px;
    }

    &--info {
        font-size: 18px;
        .out-txt {
            max-width: 250px;
            margin: -10px 0 -20px;
            font-size: 16px; //Avoids bug
        }
        .item__date {
            font-weight: normal;
            font-size: 20px;
            color: $white;
        }
    }
    &--brand {
        .list__item--wrapper {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
        }
        font-size: 24px;
        .item__logo {
            width: 150px;
            max-width: 250px;
            svg {
                * {
                    fill: $white;
                }
            }
        }
        .out-txt {
            font-size: 16px;
            width: 250px;
            margin-bottom: -15px;
        }
    }
    &--btn {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        .btn {
            display: block;
            max-width: 300px;
            width: 100%;
        }
    }
}

//  [ Responsive ]
// 768px
@media (min-width: $breakpoint-md) {
    //  + Header
    .next-ed__header {
        .container {
            flex-direction: row;
            text-align: left;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
    .next-ed__header__actual {
        margin-top: 0;
    }
    .next-ed__header__next {
        text-align: left;
        .out-txt {
            width: 151px;
        }
        &::after {
            top: 36%;
        }
    }

    //  + Title
    .next-ed__title {
        margin: 70px 0 150px;
    }

    .next-ed__title__box {
        max-width: 585px;
    }

    .next-ed__title__box__content {
        padding: 40px;
        font-size: 48px;
    }

    .next-ed__title__box__content__hglg {
        font-size: 54px;
    }

    //  + Speakers
    .next-ed__spkr {
        margin-bottom: 60px;
        .fl-txt {
            margin: 0 0 -60px;
        }
    }
    //  + Gallery
    .next-ed__gallery {
        margin-bottom: 100px;
        .fl-txt {
            margin: 0 0 -35px;
        }
    }
    //  + Footer
    .next-ed__footer__fl-bg {
        height: 135%;
    }
    .next-ed__footer__fl-bg__fl-img {
        bottom: unset;
        top: 0;
        width: 100%;
    }

    .next-ed__footer__list {
        flex-direction: row;
        justify-content: space-between;
    }

    .next-ed__footer__list__item {
        + .next-ed__footer__list__item {
            margin-top: 0;
        }

        &--info {
            flex-basis: 33%;
            font-size: 21px;
            .item__date {
                font-size: 23px;
            }
            .out-txt {
                max-width: 190px;
            }
        }
        &--brand {
            white-space: nowrap;
            padding: 0 10px;
            .item__logo {
                width: 60%;
            }
        }
        &--btn {
            justify-content: flex-end;
            flex-basis: 33%;
        }
    }
}

// 960px
@media (min-width: 960px) {
    //  + Speakers
    .next-ed__spkr {
        .spkrs__content__list {
            max-width: 1400px;
            padding: 0 15px;
        }
    }
}

// 1200px
@media (min-width: $breakpoint-xl) {
    //  + Gallery
    .next-ed__gallery {
        margin-bottom: 200px;
    }

    //  + Footer
    .next-ed__footer__fl-bg {
        height: 160%;
    }
    .next-ed__footer__fl-bg__fl-img {
        bottom: unset;
        top: 0;
        width: 100%;
    }

    .next-ed__footer__list__item {
        &--info {
            font-size: 31px;
            .item__date {
                font-size: 33px;
            }
            .out-txt {
                max-width: 232px;
            }
        }
        &--brand {
            font-size: 44px;
            .out-txt {
                width: 410px;
            }
        }
        &--btn {
            padding: 0;
        }

        + .next-ed__footer__list__item {
            margin-top: 0;
        }
    }
}
